import React, { useState, useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import {
  Backdrop,
  Modal,
  TextField,
} from '@material-ui/core';
import { Close } from '@material-ui/icons';
import PropTypes from 'prop-types';
import {
  CustomButton,
  CustomFade,
  CustomIconButton,
} from '../../assets/styled';
import Info from './Info';
import * as thunks from '../../store/thunks';
import { setToast } from '../../store/actions';

export default function NewLanguage(props) {
  const [openInfoModal, setOpenInfoModal] = useState(false);

  // const [category, setCategory] = useState({});
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');

  const dispatch = useDispatch();
  const addEditLanguage = useCallback(() => dispatch(thunks.addLanguage(name, description, props.selectedLangId && props.selectedLangId)), [dispatch, description, props.selectedLangId, name]);
  const showToast = (msg, type) => dispatch(setToast(true, msg, type));

  useEffect(() => {
    if (props.edit && !name && !description) {
      setName(props.langData?.name);
      setDescription(props.langData?.description);
    }
  }, [props.langData]);

  function saveFunc() {
    if (name && description) {
      if (openInfoModal) {
        addEditLanguage();
        setOpenInfoModal(false);
        props.onClose();
      } else {
        setOpenInfoModal(true);
      }
    } else {
      // dispatch(setToast(true, 'Alanları doldurun!', 'warning'));
      showToast('Alanları doldurun!', 'warning');
    }
  }

  function enterInput(e) {
    if (name && description && e.key === 'Enter') {
      saveFunc();
    }
  }

  return (
    <Modal
      open={props.open}
      onClose={() => props.onClose()}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <CustomFade in={props.open} onKeyDown={e => enterInput(e)}>
        <div>
          <div className="header">
            <h1>{props.edit ? 'Dili Düzenle' : 'Yeni Dil Ekle'}</h1>
            <CustomIconButton onClick={() => props.onClose()}><Close /></CustomIconButton>
          </div>
          <div className="content">
            <div className="form">
              {/* <TextField
                select
                fullWidth
                variant="standard"
                label="Kategori"
                value={category}
                onChange={e => setCategory(e.target.value)}
              >
                {selectData.map(option => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField> */}
              <TextField
                label="Dil İsmi"
                fullWidth
                variant="standard"
                value={name}
                onChange={e => setName(e.target.value)}
              />
              <TextField
                label="Dil Açıklaması"
                fullWidth
                variant="standard"
                multiline
                maxRows={4}
                value={description}
                onChange={e => setDescription(e.target.value)}
              />
              <div className="buttonWrapper">
                <CustomButton onClick={() => props.onClose()} lined>
                  Vazgeç
                </CustomButton>
                <CustomButton purple onClick={() => saveFunc()}>
                  Kaydet
                </CustomButton>
              </div>
            </div>
          </div>
          {
            openInfoModal && (
              <Info
                open={openInfoModal}
                type="Warning"
                title={props.edit ? 'Dil Düzenleniyor' : 'Yeni Dil Ekleniyor'}
                text="Diller kısmından tekrar düzenleyebilirsiniz."
                cancelButton={(<CustomButton lined onClick={() => setOpenInfoModal(false)}>Vazgeç</CustomButton>)}
                confirmButton={(<CustomButton purple onClick={() => saveFunc()}>Tamam</CustomButton>)}
              />
            )
          }
        </div>
      </CustomFade>
    </Modal>
  );
}

NewLanguage.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  selectedLangId: PropTypes.number.isRequired,
  edit: PropTypes.bool,
  langData: PropTypes.object,
};

NewLanguage.defaultProps = {
  edit: false,
  langData: {},
};
