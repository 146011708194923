import { fileRequest, request } from '../commons/utils';
import * as url from '../commons/constants';
import * as action from './actions';

export const getStatics = () => async (dispatch) => {
  const result = await request(url.STATICS, 'GET', false, true);
  if (result !== 'invalid') {
    if (result.status) {
      dispatch(action.setStatics(result.data));
    }
  }
};

export const getInit = () => async (dispatch) => {
  const result = await request(url.INIT, 'GET', false, true);
  if (result !== 'invalid') {
    if (result.data?.user_type_id !== 1) {
      localStorage.clear();
      window.location.replace('/');
    }
    if (result.status) {
      dispatch(action.setActiveUser(result.data));
    }
  }
};

export const doLogin = (mail, password) => async (dispatch) => {
  const jsonStr = JSON.stringify({ mail, password });
  const result = await request(url.LOGIN, 'POST', jsonStr, true);
  if (result !== 'invalid') {
    if (result.status) {
      // dispatch(action.setActiveUser(result.data));
      localStorage.setItem('token', result.data.token);
      window.location.replace('/');
    } else {
      dispatch(action.setToast(true, result.message, 'warning'));
    }
  } else {
    dispatch(action.setToast(true, 'Sunucu ile bağlantı kurulamadı!', 'warning'));
  }
};

export const doRegister = data => async (dispatch) => {
  const jsonStr = JSON.stringify(data);
  const result = await request(url.REGISTER, 'POST', jsonStr, true);
  if (result !== 'invalid') {
    if (result.status) {
      dispatch(action.setToast(true, result.message, 'success'));
    } else {
      dispatch(action.setToast(true, result.message, 'warning'));
    }
  } else {
    dispatch(action.setToast(true, 'Sunucu ile bağlantı kurulamadı!', 'warning'));
  }
};

export const getLanguages = id => async (dispatch) => {
  let newUrl = `${url.LANGUAGE}/all`;
  if (id) {
    newUrl = `${url.LANGUAGE}/${id}`;
  }
  const result = await request(newUrl, 'GET', false, true);
  if (result !== 'invalid') {
    if (result.status) {
      if (id) {
        dispatch(action.setLanguage(result.data?.filter?.(l => !l.is_removed)));
        // dispatch(action.setToast(true, 'Dil Bilgisi Getirildi.', 'success'));
      } else {
        dispatch(action.setAllLanguages(result.data));
        // dispatch(action.setToast(true, 'Dil Bilgileri Getirildi.', 'success'));
      }
    }
  } else {
    dispatch(action.setToast(true, 'Sunucu ile bağlantı kurulamadı!', 'warning'));
  }
};

export const addLanguage = (name, description, id) => async (dispatch) => {
  let newUrl = url.LANGUAGE_ADMIN;
  let method = 'POST';
  if (id) {
    newUrl = `${url.LANGUAGE_ADMIN}/${id}`;
    method = 'PUT';
  }
  const jsonStr = JSON.stringify({ name, description });
  const result = await request(newUrl, method, jsonStr, true);
  if (result !== 'invalid') {
    if (result.status) {
      dispatch(action.setToast(true, result.message, 'success'));
      dispatch(getLanguages());
    } else {
      dispatch(action.setToast(true, result.message, 'warning'));
    }
  } else {
    dispatch(action.setToast(true, 'Sunucu ile bağlantı kurulamadı!', 'warning'));
  }
};

export const deleteLanguage = id => async (dispatch) => {
  const result = await request(`${url.LANGUAGE_ADMIN}/${id}`, 'DELETE', false, true);
  if (result !== 'invalid') {
    if (result.status) {
      dispatch(action.setToast(true, result.message, 'success'));
      dispatch(getLanguages());
    } else {
      dispatch(action.setToast(true, result.message, 'warning'));
    }
  } else {
    dispatch(action.setToast(true, 'Sunucu ile bağlantı kurulamadı!', 'warning'));
  }
};

export const createWord = (files, data) => async (dispatch) => {
  // const files = [
  //   image1: files.image,
  //   sound,
  //   soundSimilar,
  // ];

  // const data = {
  //   name: 'Test',
  //   meaning: 'test',
  //   reading: 'testor',
  //   similar: 'adasd',
  //   lang_id: 1,
  //   text: 'Test',
  //   image_url: 'string',
  //   voice_reading_url: 'string',
  //   voice_memory_url: 'string',
  // };
  const result = await fileRequest(`${url.WORD}`, 'POST', files, JSON.stringify(data));
  if (result !== 'invalid') {
    if (result.status) {
      dispatch(action.setToast(true, result.message, 'success'));
    } else {
      dispatch(action.setToast(true, result.message, 'warning'));
    }
    dispatch(action.getWord(data?.category_id));
  } else {
    dispatch(action.setToast(true, 'Sunucu ile bağlantı kurulamadı!', 'warning'));
  }
};

export const getWord = id => async (dispatch) => {
  let newUrl = `${url.WORD_PR}/all`;
  if (id) {
    newUrl = `${url.WORD_PR}/1/${id}`;
  }
  const result = await request(newUrl, 'GET', false, true);
  dispatch(action.setWord([]));
  dispatch(action.setAllWords([]));
  if (result !== 'invalid') {
    if (result.status) {
      if (id) {
        dispatch(action.setWord(result.data));
        // dispatch(action.setToast(true, 'Kelime Bilgisi Getirildi.', 'success'));
      } else {
        dispatch(action.setAllWords(result.data));
        // dispatch(action.setToast(true, 'Kelime Bilgileri Getirildi.', 'success'));
      }
    }
  } else {
    dispatch(action.setToast(true, 'Sunucu ile bağlantı kurulamadı!', 'warning'));
  }
};

export const getWordDetail = id => async (dispatch) => {
  const result = await request(`${url.WORD_PR}/${id}`, 'GET', false, true);
  dispatch(action.setWordDetail([]));
  if (result !== 'invalid') {
    if (result.status) {
      dispatch(action.setWordDetail(result.data));
      // dispatch(action.setToast(true, 'Kelime Detay Bilgisi Getirildi.', 'success'));
    }
  } else {
    dispatch(action.setToast(true, 'Sunucu ile bağlantı kurulamadı!', 'warning'));
  }
};

export const editWord = (data, id) => async (dispatch) => {
  const jsonStr = JSON.stringify(data);
  const result = await request(`${url.WORD}/${id}`, 'PUT', jsonStr, true);
  if (result !== 'invalid') {
    if (result.status) {
      dispatch(action.setToast(true, result.message, 'success'));
      dispatch(getWord(data?.category_id));
    } else {
      dispatch(action.setToast(true, result.message, 'warning'));
    }
  } else {
    dispatch(action.setToast(true, 'Sunucu ile bağlantı kurulamadı!', 'warning'));
  }
};

export const deleteWord = (id, getWordId) => async (dispatch) => {
  const result = await request(`${url.WORD}/${id}`, 'DELETE', false, true);
  if (result !== 'invalid') {
    if (result.status) {
      dispatch(action.setToast(true, result.message, 'success'));
      dispatch(getWord(getWordId));
    } else {
      dispatch(action.setToast(true, result.message, 'warning'));
    }
  } else {
    dispatch(action.setToast(true, 'Sunucu ile bağlantı kurulamadı!', 'warning'));
  }
};

export const createWordExample = data => async (dispatch) => {
  const jsonStr = JSON.stringify(data);
  const result = await fileRequest(`${url.WORD}/Example`, 'POST', jsonStr, true);
  if (result !== 'invalid') {
    if (result.status) {
      dispatch(action.setToast(true, result.message, 'success'));
    } else {
      dispatch(action.setToast(true, result.message, 'warning'));
    }
    // dispatch(action.getWord(data?.category_id));
  } else {
    dispatch(action.setToast(true, 'Sunucu ile bağlantı kurulamadı!', 'warning'));
  }
};

export const editWordExample = (data, id) => async (dispatch) => {
  const jsonStr = JSON.stringify(data);
  const result = await request(`${url.WORD}/Example/${id}`, 'PUT', jsonStr, true);
  if (result !== 'invalid') {
    if (result.status) {
      dispatch(action.setToast(true, result.message, 'success'));
      // dispatch(getWord());
    } else {
      dispatch(action.setToast(true, result.message, 'warning'));
    }
  } else {
    dispatch(action.setToast(true, 'Sunucu ile bağlantı kurulamadı!', 'warning'));
  }
};

export const deleteWordExample = id => async (dispatch) => {
  const result = await request(`${url.WORD}/Example/${id}`, 'DELETE', false, true);
  if (result !== 'invalid') {
    if (result.status) {
      dispatch(action.setToast(true, result.message, 'success'));
      dispatch(getWord());
    } else {
      dispatch(action.setToast(true, result.message, 'warning'));
    }
  } else {
    dispatch(action.setToast(true, 'Sunucu ile bağlantı kurulamadı!', 'warning'));
  }
};

export const getCategory = id => async (dispatch) => {
  const result = await request(`${url.CATEGORY_PR}/${id}`, 'GET', false, true);
  dispatch(action.setCategory([]));
  if (result !== 'invalid') {
    if (result.status) {
      dispatch(action.setCategory(result.data));
      // dispatch(action.setToast(true, 'Kategori Bilgisi Getirildi.', 'success'));
    }
  } else {
    dispatch(action.setToast(true, 'Sunucu ile bağlantı kurulamadı!', 'warning'));
  }
};

export const getCategorySub = id => async (dispatch) => {
  const result = await request(`${url.CATEGORY_PR}/sub/${id}`, 'GET', false, true);
  dispatch(action.setCategorySub([]));
  if (result !== 'invalid') {
    if (result.status) {
      dispatch(action.setCategorySub(result.data));
      // dispatch(action.setToast(true, 'Alt Kategori Bilgisi Getirildi.', 'success'));
    }
  } else {
    dispatch(action.setToast(true, 'Sunucu ile bağlantı kurulamadı!', 'warning'));
  }
};

export const createCategory = data => async (dispatch) => {
  const jsonStr = JSON.stringify(data);
  const result = await request(`${url.CATEGORY}`, 'POST', jsonStr, true);
  if (result !== 'invalid') {
    if (result.status) {
      dispatch(action.setToast(true, result.message, 'success'));
      dispatch(getCategory(data?.lang_id));
    } else {
      dispatch(action.setToast(true, result.message, 'warning'));
    }
  } else {
    dispatch(action.setToast(true, 'Sunucu ile bağlantı kurulamadı!', 'warning'));
  }
};

export const editCategory = (data, id) => async (dispatch) => {
  const jsonStr = JSON.stringify({ data });
  const result = await request(`${url.CATEGORY}/${id}`, 'PUT', jsonStr, true);
  if (result !== 'invalid') {
    if (result.status) {
      dispatch(action.setToast(true, result.message, 'success'));
      dispatch(getCategory(data?.lang_id));
    } else {
      dispatch(action.setToast(true, result.message, 'warning'));
    }
  } else {
    dispatch(action.setToast(true, 'Sunucu ile bağlantı kurulamadı!', 'warning'));
  }
};

export const deleteCategory = (id, langId) => async (dispatch) => {
  const result = await request(`${url.CATEGORY}/${id}`, 'DELETE', false, true);
  if (result !== 'invalid') {
    if (result.status) {
      dispatch(action.setToast(true, result.message, 'success'));
      dispatch(getCategory(langId));
    } else {
      dispatch(action.setToast(true, result.message, 'warning'));
    }
  } else {
    dispatch(action.setToast(true, 'Sunucu ile bağlantı kurulamadı!', 'warning'));
  }
};

export const getUser = id => async (dispatch) => {
  let newUrl = `${url.USER}`;
  if (id) {
    newUrl = `${url.USER}/${id}`;
  }
  const result = await request(newUrl, 'GET', false, true);
  if (result !== 'invalid') {
    if (result.status) {
      if (id) {
        dispatch(action.setUser(result.data));
        // dispatch(action.setToast(true, 'Kullanıcı Bilgisi Getirildi.', 'success'));
      } else {
        dispatch(action.setAllUser(result.data));
        // dispatch(action.setToast(true, 'Kullanıcıların Bilgileri Getirildi.', 'success'));
      }
    }
  } else {
    dispatch(action.setToast(true, 'Sunucu ile bağlantı kurulamadı!', 'warning'));
  }
};

export const createUser = (data, id) => async (dispatch) => {
  let newUrl = url.USER;
  let method = 'POST';
  if (id) {
    newUrl = `${url.USER}/${id}`;
    method = 'PUT';
  }
  const jsonStr = JSON.stringify(data);
  const result = await request(newUrl, method, jsonStr, true);
  if (result !== 'invalid') {
    if (result.status) {
      dispatch(action.setToast(true, result.message, 'success'));
      dispatch(getUser());
    } else {
      dispatch(action.setToast(true, result.message, 'warning'));
    }
  } else {
    dispatch(action.setToast(true, 'Sunucu ile bağlantı kurulamadı!', 'warning'));
  }
};

export const deleteUser = id => async (dispatch) => {
  const result = await request(`${url.USER}/${id}`, 'DELETE', false, true);
  if (result !== 'invalid') {
    if (result.status) {
      dispatch(action.setToast(true, result.message, 'success'));
      dispatch(getUser());
    } else {
      dispatch(action.setToast(true, result.message, 'warning'));
    }
  } else {
    dispatch(action.setToast(true, 'Sunucu ile bağlantı kurulamadı!', 'warning'));
  }
};

export const getPackage = id => async (dispatch) => {
  let newUrl = `${url.PACKAGE}/packages`;
  if (id) {
    newUrl = `${url.PACKAGE_PR}/${id}`;
  }
  const result = await request(newUrl, 'GET', false, true);
  if (result !== 'invalid') {
    if (id) {
      dispatch(action.setPackage(result.data));
      // dispatch(action.setToast(true, 'Paket Bilgisi Getirildi.', 'success'));
    } else {
      dispatch(action.setAllPackage(result.data));
      // dispatch(action.setToast(true, 'Paketlerin Bilgileri Getirildi.', 'success'));
    }
  } else {
    dispatch(action.setToast(true, 'Sunucu ile bağlantı kurulamadı!', 'warning'));
  }
};

export const createPackage = data => async (dispatch) => {
  const jsonStr = JSON.stringify(data);
  const result = await request(`${url.PACKAGE}`, 'POST', jsonStr, true);
  if (result !== 'invalid') {
    if (result.status) {
      dispatch(action.setToast(true, result.message, 'success'));
      dispatch(getPackage());
    } else {
      dispatch(action.setToast(true, result.message, 'warning'));
    }
  } else {
    dispatch(action.setToast(true, 'Sunucu ile bağlantı kurulamadı!', 'warning'));
  }
};

export const editPackage = (data, id) => async (dispatch) => {
  const jsonStr = JSON.stringify(data);
  const result = await request(`${url.PACKAGE}/${id}`, 'PUT', jsonStr, true);
  if (result !== 'invalid') {
    if (result.status) {
      dispatch(action.setToast(true, result.message, 'success'));
      dispatch(getPackage());
    } else {
      dispatch(action.setToast(true, result.message, 'warning'));
    }
  } else {
    dispatch(action.setToast(true, 'Sunucu ile bağlantı kurulamadı!', 'warning'));
  }
};

export const deletePackage = id => async (dispatch) => {
  const result = await request(`${url.PACKAGE}/${id}`, 'DELETE', false, true);
  if (result !== 'invalid') {
    if (result.status) {
      dispatch(action.setToast(true, result.message, 'success'));
      dispatch(getPackage());
    } else {
      dispatch(action.setToast(true, result.message, 'warning'));
    }
  } else {
    dispatch(action.setToast(true, 'Sunucu ile bağlantı kurulamadı!', 'warning'));
  }
};

export const getCoupon = id => async (dispatch) => {
  let newUrl = `${url.COUPON}`;
  if (id) {
    newUrl = `${url.COUPON}/${id}`;
  }
  const result = await request(newUrl, 'GET', false, true);
  if (result !== 'invalid') {
    if (id) {
      dispatch(action.setCoupon(result.data));
      // dispatch(action.setToast(true, 'Kupon Bilgisi Getirildi.', 'success'));
    } else {
      dispatch(action.setAllCoupon(result.data));
      // dispatch(action.setToast(true, 'Kuponların Bilgileri Getirildi.', 'success'));
    }
  } else {
    dispatch(action.setToast(true, 'Sunucu ile bağlantı kurulamadı!', 'warning'));
  }
};

export const createCoupon = data => async (dispatch) => {
  const jsonStr = JSON.stringify(data);
  const result = await request(`${url.COUPON}`, 'POST', jsonStr, true);
  if (result !== 'invalid') {
    if (result.status) {
      dispatch(action.setToast(true, result.message, 'success'));
      dispatch(getCoupon());
    } else {
      dispatch(action.setToast(true, result.message, 'warning'));
    }
  } else {
    dispatch(action.setToast(true, 'Sunucu ile bağlantı kurulamadı!', 'warning'));
  }
};

export const editCoupon = (data, id) => async (dispatch) => {
  let newUrl = `${url.COUPON}`;
  let method = 'POST';
  if (id) {
    newUrl = `${url.COUPON}/${id}`;
    method = 'PUT';
  }
  const jsonStr = JSON.stringify(data);
  const result = await request(newUrl, method, jsonStr, true);
  if (result !== 'invalid') {
    if (result.status) {
      dispatch(action.setToast(true, result.message, 'success'));
      dispatch(getCoupon());
    } else {
      dispatch(action.setToast(true, result.message, 'warning'));
    }
  } else {
    dispatch(action.setToast(true, 'Sunucu ile bağlantı kurulamadı!', 'warning'));
  }
};

export const deleteCoupon = id => async (dispatch) => {
  const result = await request(`${url.COUPON}/${id}`, 'DELETE', false, true);
  if (result !== 'invalid') {
    if (result.status) {
      dispatch(action.setToast(true, result.message, 'success'));
      dispatch(getCoupon());
    } else {
      dispatch(action.setToast(true, result.message, 'warning'));
    }
  } else {
    dispatch(action.setToast(true, 'Sunucu ile bağlantı kurulamadı!', 'warning'));
  }
};

export const assignPackage = data => async (dispatch) => {
  const jsonStr = JSON.stringify(data);
  const result = await request(`${url.PACKAGE_ASSING}`, 'POST', jsonStr, true);
  if (result !== 'invalid') {
    if (result.status) {
      dispatch(action.setToast(true, result.message, 'success'));
      return result;
    }
    dispatch(action.setToast(true, result.message, 'warning'));
  } else {
    dispatch(action.setToast(true, 'Sunucu ile bağlantı kurulamadı!', 'warning'));
  }
};

export const createBlog = data => async (dispatch) => {
  const jsonStr = JSON.stringify(data);
  const result = await request(url.BLOG, 'POST', jsonStr, true);
  if (result !== 'invalid') {
    if (result.status) {
      dispatch(action.setToast(true, result.message, 'success'));
      setTimeout(() => {
        window.location.replace('/#/Blogs');
      }, 1000);
    } else {
      dispatch(action.setToast(true, result.message, 'warning'));
    }
  } else {
    dispatch(action.setToast(true, 'Sunucu ile bağlantı kurulamadı!', 'warning'));
  }
};

export const getBlogs = () => async (dispatch) => {
  const result = await request(url.BLOG, 'GET', false, true);
  if (result !== 'invalid') {
    if (result.status) {
      dispatch(action.setBlogs(result.data));
    } else {
      dispatch(action.setToast(true, result.message, 'warning'));
    }
  } else {
    dispatch(action.setToast(true, 'Sunucu ile bağlantı kurulamadı!', 'warning'));
  }
};

export const updateBlogs = ({ id, data }) => async (dispatch) => {
  const jsonStr = JSON.stringify(data);
  const result = await request(`${url.BLOG}/${id}`, 'PUT', jsonStr, true);
  if (result !== 'invalid') {
    if (result.status) {
      dispatch(action.setToast(true, result.message, 'success'));
      dispatch(getBlogs());
    } else {
      dispatch(action.setToast(true, result.message, 'warning'));
    }
  } else {
    dispatch(action.setToast(true, 'Sunucu ile bağlantı kurulamadı!', 'warning'));
  }
};

export const uploadFile = (file, data) => async (dispatch) => {
  const result = await fileRequest(`${url.HELPERS}/uploadFile`, 'POST', file, data);
  if (result !== 'invalid') {
    if (result.status) {
      dispatch(action.setLastUplaodFile(result.data));
      dispatch(action.setToast(true, result.message, 'success'));
    } else {
      dispatch(action.setToast(true, result.message, 'warning'));
    }
    dispatch(getLanguages());
  } else {
    dispatch(action.setToast(true, 'Sunucu ile bağlantı kurulamadı!', 'warning'));
  }
};

export const getPaymentList = data => async (dispatch) => {
  const jsonStr = JSON.stringify(data);
  const result = await request(url.PAYMENT_LIST, 'POST', jsonStr, true);
  if (result !== 'invalid') {
    if (result.status) {
      dispatch(action.setPaymentList(result.data));
    } else {
      dispatch(action.setToast(true, result.message, 'warning'));
    }
  }
};

export const changePaymentStatus = (id, statu, listParams) => async (dispatch) => {
  const jsonStr = JSON.stringify({ status: statu });
  const result = await request(`${url.CHANGE_PAYMENT_STATUS}/${id}`, 'POST', jsonStr, true);
  if (result !== 'invalid') {
    if (result.status) {
      dispatch(action.setToast(true, result.message, 'success'));
      dispatch(getPaymentList(listParams));
    } else {
      dispatch(action.setToast(true, result.message, 'warning'));
    }
  }
};

export const getUserPaymentList = user_id => async (dispatch) => {
  const result = await request(`${url.PAYMENT_LIST}/${user_id}`, 'GET', false, true);
  if (result !== 'invalid') {
    if (result.status) {
      dispatch(action.setUserPaymentList(result.data));
    } else {
      dispatch(action.setToast(true, result.message, 'warning'));
    }
  }
};

export const getBulkSales = () => async (dispatch) => {
  const result = await request(url.BULK_SALES, 'GET', false, true);
  if (result !== 'invalid') {
    if (result.status) {
      dispatch(action.setBulkSales(result.data));
    } else {
      dispatch(action.setToast(true, result.message, 'warning'));
    }
  }
};

export const createBulkSale = data => async (dispatch) => {
  const jsonStr = JSON.stringify({ ...data });
  const result = await request(url.BULK_SALES, 'POST', jsonStr, true);
  if (result !== 'invalid') {
    if (result.status) {
      dispatch(action.setToast(true, result.message, 'success'));
      // dispatch(action.setUserPaymentList(result.data));
    } else {
      dispatch(action.setToast(true, result.message, 'warning'));
    }
  }
};

export const createPayment = data => async (dispatch) => {
  const jsonStr = JSON.stringify({ ...data });
  const result = await request(url.CREATE_PAYMENT, 'POST', jsonStr, true);
  if (result !== 'invalid') {
    if (result.status) {
      dispatch(action.setToast(true, result.message, 'success'));
      // dispatch(action.setUserPaymentList(result.data));
    } else {
      dispatch(action.setToast(true, result.message, 'warning'));
    }
  }
};

export const getWordReports = data => async (dispatch) => {
  const jsonStr = JSON.stringify(data);
  const result = await request(`${url.WORD}/getWordReports`, 'POST', jsonStr, true);
  if (result !== 'invalid') {
    if (result.status) {
      dispatch(action.setWordReports(result.data));
    } else {
      dispatch(action.setToast(true, result.message, 'warning'));
    }
  }
};

export const changeWordReport = (id, data) => async (dispatch) => {
  const jsonStr = JSON.stringify(data);
  const result = await request(`${url.WORD}/changeWordReport/${id}`, 'POST', jsonStr, true);
  if (result !== 'invalid') {
    if (result.status) {
      dispatch(action.setToast(true, result.message, 'success'));
      dispatch(getWordReports({ type: 'ALL', isFixed: 'ALL' }));
    } else {
      dispatch(action.setToast(true, result.message, 'warning'));
    }
  }
};

export const sendNotify = data => async (dispatch) => {
  const jsonStr = JSON.stringify(data);
  const result = await request(`${url.HELPERS}/sendNotify`, 'POST', jsonStr, true);
  if (result !== 'invalid') {
    if (result.status) {
      dispatch(action.setToast(true, result.message, 'success'));
    } else {
      dispatch(action.setToast(true, result.message, 'warning'));
    }
  }
};
