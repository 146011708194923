import React, { useState, useCallback } from 'react';
// import { useSelector, useDispatch } from 'react-redux';
import {
  WebPageWrapper,
} from '../assets/styled';
// import * as thunks from '../store/thunks';
import package1 from '../assets/img/package1.jpg';
import package2 from '../assets/img/package2.jpg';
import package3 from '../assets/img/package3.jpg';
import package4 from '../assets/img/package4.jpg';

function WebPage() {
  // const [email, setEmail] = useState('admin@test.com');
  // const [password, setPassword] = useState('123456');

  // Redux Store Verisi çağırma
  // const activeUser = useSelector(state => state.activeUser);

  // Action Listesi
  // const dispatch = useDispatch();
  // const doLogin = useCallback(() => dispatch(thunks.doLogin(email, password)), [dispatch, email, password]);


  // Fonksiyon Direkt Çalışacaksa
  // useEffect(() => {
  //   dispatch(thunks.doLogin(email, password));
  // }, [dispatch, email, password]);

  return (
    <WebPageWrapper>
      asd
      <img src={package1} alt="package4" />
      <img src={package2} alt="package4" />
      <img src={package3} alt="package4" />
      <img src={package4} alt="package4" />
    </WebPageWrapper>
  );
}

export default WebPage;
