import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Pause, PlayArrow } from '@material-ui/icons';
import { CustomButton } from '../assets/styled';

const useAudio = (url) => {
  const [audio] = useState(new Audio(url));
  const [playing, setPlaying] = useState(false);

  const toggle = () => setPlaying(!playing);

  useEffect(() => {
    // playing ? audio.play() : audio.pause();
    if (playing) {
      audio.play();
    } else {
      audio.pause();
    }
  }, [playing, audio]);

  useEffect(() => {
    audio.addEventListener('ended', () => setPlaying(false));
    return () => {
      audio.removeEventListener('ended', () => setPlaying(false));
    };
  }, [audio]);

  return [playing, toggle];
};

const Player = ({ url }) => {
  const [playing, toggle] = useAudio(url);

  return (
    <div>
      <CustomButton purple onClick={toggle} startIcon={!playing ? <PlayArrow /> : <Pause />}>{playing ? 'Pause' : 'Play'}</CustomButton>
    </div>
  );
};


Player.propTypes = {
  url: PropTypes.string.isRequired,
};

Player.defaultProps = {
};

export default Player;
