import React, { useState, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Dialog,
  Grid,
  MenuItem,
  TextField,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import { useParams } from 'react-router';
import {
  CustomButton,
  CustomDialog,
  PageWrapper,
} from '../assets/styled';
import Info from '../components/modals/Info';
import * as thunks from '../store/thunks';
import { setToast } from '../store/actions';


export default function CreateWord(props) {
  const params = useParams();
  const edit = params?.id !== 'new';

  const [openInfoModal, setOpenInfoModal] = useState(false);

  const [name, setName] = useState('');
  const [meaning, setMeaning] = useState('');
  const [reading, setReading] = useState('');
  const [similar, setSimilar] = useState('');
  const [langId, setLangId] = useState('');
  const [category, setCategory] = useState('');
  const [categorySub, setCategorySub] = useState('');
  const [text, setText] = useState('');

  const [image, setImage] = useState('');
  const [sound, setSound] = useState('');
  const [soundSimilar, setSoundSimilar] = useState('');

  const [example, setExample] = useState('');
  const [translate, setTranslate] = useState('');
  const [exampleSound, setExampleSound] = useState('');

  const [fileDialogOpen, setFileDialogOpen] = useState(false);
  const [exampleDialogOpen, setExampleDialogOpen] = useState(false);


  const dataExample = [
    {
      example,
      translate,
      voice_url: 'exampleSound',
    },
  ];

  const datas = {
    name,
    meaning,
    reading,
    similar,
    lang_id: parseInt(langId, 10),
    text,
    image_url: 'string',
    voice_reading_url: 'string',
    voice_memory_url: 'string',
    category_id: categorySub || category,
    WordExamples: dataExample,
  };

  const files = [
    image,
    sound,
    soundSimilar,
  ];

  // Redux Store Verisi çağırma
  const allLanguages = useSelector(state1 => state1.allLanguages);
  const categories = useSelector(state1 => state1.category);
  const categoriesSub = useSelector(state1 => state1.categorySub);
  const wordDetail = useSelector(state1 => state1.wordDetail);

  const dispatch = useDispatch();
  const createWord = useCallback(() => dispatch(thunks.createWord(files, datas)), [dispatch, files, datas]);
  const editWord = useCallback(() => dispatch(thunks.editWord(datas, props.selectedWordId)), [dispatch, datas, props.selectedWordId]);

  const createWordExample = useCallback(() => dispatch(thunks.createWordExample(dataExample)), [dispatch, dataExample]);
  const editWordExample = useCallback(() => dispatch(thunks.editWord(dataExample, props.selectedWordId)), [dispatch, dataExample, props.selectedWordId]);
  const showToast = (msg, type) => dispatch(setToast(true, msg, type));

  // useEffect(() => {
  //   if (!allLanguages?.length > 0) {
  //     dispatch(thunks.getLanguages());
  //   }
  // }, [dispatch, allLanguages]);

  // {
  //   "name": "string",
  //   "meaning": "string",
  //   "reading": "string",
  //   "similar": "string",
  //   "lang_id": 0,
  //   "text": "string",
  //   "image_url": "string",
  //   "voice_reading_url": "string",
  //   "voice_memory_url": "string"
  // }

  useEffect(() => {
    // dispatch(thunks.getCategory(params.id));
    dispatch(thunks.getWordDetail(params.id));
  }, [dispatch, params.id]);

  useEffect(() => {
    dispatch(thunks.getLanguages());
  }, [dispatch]);

  useEffect(() => {
    if (!edit) {
      dispatch(thunks.getCategory(wordDetail?.category_id));
      dispatch(thunks.getCategorySub(wordDetail?.category_id));
    }
  }, [dispatch, wordDetail]);

  useEffect(() => {
    if (edit && wordDetail && !name && !meaning && !reading && !similar && !langId && !text && !image && !sound && !soundSimilar) {
      setName(wordDetail?.name);
      setMeaning(wordDetail?.meaning);
      setReading(wordDetail?.reading);
      setSimilar(wordDetail?.similar);
      setLangId(wordDetail?.lang_id);
      setText(wordDetail?.text);
      setImage(wordDetail?.image_url);
      setSound(wordDetail?.voice_reading_url);
      setSoundSimilar(wordDetail?.voice_memory_url);
      setCategory(props.activeCatId);
    }
  }, [wordDetail]);

  function saveFunc() {
    if (name && meaning && reading && similar && langId && text && image && sound && soundSimilar && category) {
      if (openInfoModal) {
        if (edit) {
          editWord();
        } else {
          createWord();
        }
        props.onClose();
      }
      setOpenInfoModal(true);
    } else {
      // dispatch(setToast(true, 'Alanları doldurun!', 'warning'));
      showToast('Alanları doldurun!', 'warning');
    }
  }

  function enterInput(e) {
    if (e.key === 'Enter') {
      // saveFunc(true);
    }
  }

  function cancelFiles() {
    if (!edit) {
      setImage('');
      setSound('');
      setSoundSimilar('');
    }
    setFileDialogOpen(false);
  }

  function saveExample() {
    if (!edit) {
      createWordExample();
    } else {
      editWordExample();
    }
    setFileDialogOpen(false);
  }

  function cancelExample() {
    if (!edit) {
      setExample('');
      setTranslate('');
      setExampleSound('');
    }
    setExampleDialogOpen(false);
  }

  return (
    <>
      <PageWrapper onKeyDown={e => enterInput(e)}>
        <h1 className="title">{edit ? 'Kelimeyi Düzenle' : 'Yeni Kelime Ekle'}</h1>
        <Grid container spacing={3}>
          <Grid item lg={3} md={4} sm={6} xs={12}>
            <TextField
              label="Kelime İsmi"
              fullWidth
              variant="standard"
              value={name}
              autoFocus={!edit}
              onChange={e => setName(e.target.value)}
            />
          </Grid>
          <Grid item lg={3} md={4} sm={6} xs={12}>
            <TextField
              label="Anlamı"
              fullWidth
              variant="standard"
              value={meaning}
              onChange={e => setMeaning(e.target.value)}
            />
          </Grid>
          <Grid item lg={3} md={4} sm={6} xs={12}>
            <TextField
              label="Okunuşu"
              fullWidth
              variant="standard"
              value={reading}
              onChange={e => setReading(e.target.value)}
            />
          </Grid>
          <Grid item lg={3} md={4} sm={6} xs={12}>
            <TextField
              label="Benzer"
              fullWidth
              variant="standard"
              value={similar}
              onChange={e => setSimilar(e.target.value)}
            />
          </Grid>
          <Grid item lg={3} md={4} sm={6} xs={12}>
            <TextField
              select
              fullWidth
              variant="standard"
              label="Dil"
              value={langId}
              onChange={(e) => { dispatch(thunks.getCategory(e.target.value)); setLangId(e.target.value); setCategory(''); setCategorySub(''); }}
            >
              {allLanguages?.map(option => (
                <MenuItem key={option.id} value={option.id}>
                  {option.name}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item lg={3} md={4} sm={6} xs={12}>
            <TextField
              select
              fullWidth
              variant="standard"
              label="Kategori(Ana)"
              value={category}
              onChange={(e) => { dispatch(thunks.getCategorySub(e.target.value)); setCategory(e.target.value); setCategorySub(''); }}
              disabled={!(categories?.length > 0) || !langId}
            >
              {categories?.map(option => (
                <MenuItem key={option.id} value={option.id}>
                  {option.name}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item lg={3} md={4} sm={6} xs={12}>
            <TextField
              select
              fullWidth
              variant="standard"
              label="Kategori(Alt)"
              value={categorySub}
              onChange={e => setCategorySub(e.target.value)}
              disabled={!(categoriesSub?.length > 0) || !category}
            >
              {categoriesSub?.map(option => (
                <MenuItem key={option.id} value={option.id}>
                  {option.name}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <TextField
              label="Metin"
              fullWidth
              variant="standard"
              multiline
              maxRows={4}
              value={text}
              onChange={e => setText(e.target.value)}
            />
          </Grid>
          {/* <div className="itemWrapper">
            <p>{(image && sound && soundSimilar) ? 'Dosya Seçildi' : 'Dosya Seçilmedi'}</p>
            <CustomButton purple onClick={() => setFileDialogOpen(true)}>Seç</CustomButton>
          </div> */}
          {/* <div className="itemWrapper">
            <p>{(example && translate && exampleSound) ? 'Örnek Seçildi' : 'Örnek Seçilmedi'}</p>
            <CustomButton purple onClick={() => setExampleDialogOpen(true)}>Seç</CustomButton>
          </div> */}
          <Grid item lg={4} md={6} sm={12} xs={12}>
            <div className="fileInputWrapper">
              <TextField
                label="Resim"
                fullWidth
                variant="standard"
                value={image?.name || ''}
                disabled
                InputLabelProps={{ focused: true, shrink: true }}
                // onChange={e => setImage(e.target.value)}
              />
              <CustomButton component="label">
                {image?.name ? 'Eklendi' : 'Ekle'}
                <input
                  type="file"
                  hidden
                  accept="image/png, image/gif, image/jpeg"
                  onChange={(e) => {
                    const file = e.target.files[0];
                    const blob = file && file?.slice(0, file.size, file.type);
                    const newFile = file && new File([blob], `image.${file?.name?.split('.')?.pop()}`, { type: file.type });
                    setImage(newFile);
                  }}
                />
              </CustomButton>
            </div>
            {
              edit ? <img src={image} alt="Resim" style={{ marginTop: 5 }} /> : (image && <img src={image && URL.createObjectURL(image)} alt="Resim" style={{ marginTop: 5 }} />)
            }
          </Grid>
          <Grid item lg={4} md={6} sm={12} xs={12}>
            <div className="fileInputWrapper">
              <TextField
                label="Okunuş Sesi"
                fullWidth
                variant="standard"
                value={sound?.name || ''}
                disabled
                InputLabelProps={{ focused: true, shrink: true }}
                // onChange={e => setSound(e.target.value)}
              />
              <CustomButton component="label">
                {sound?.name ? 'Eklendi' : 'Ekle'}
                <input
                  type="file"
                  hidden
                  accept=".mp3,audio/*"
                  onChange={(e) => {
                    const file = e.target.files[0];
                    const blob = file && file?.slice(0, file.size, file.type);
                    const newFile = file && new File([blob], `memory.${file?.name?.split('.')?.pop()}`, { type: file.type });
                    setSound(newFile);
                  }}
                />
              </CustomButton>
            </div>
            <audio key={sound} controls={sound} style={{ width: '100%', marginTop: 5 }}>
              <track kind="captions" {...props} />
              {/* <source src={sound && URL.createObjectURL(new Audio(sound))} type="audio/mpeg" /> */}
              {
                edit ? <source key={sound} src={sound} type="audio/mpeg" /> : <source src="https://egitim.hafizamerkezi.com/files/kelimeler/ses/10825.mp3" type="audio/mpeg" />
              }
              Your browser dosent support audio
            </audio>
          </Grid>
          <Grid item lg={4} md={6} sm={12} xs={12}>
            <div className="fileInputWrapper">
              <TextField
                label="Benzer Sesi"
                fullWidth
                variant="standard"
                value={soundSimilar?.name || ''}
                disabled
                InputLabelProps={{ focused: true, shrink: true }}
                // onChange={e => setSoundSimilar(e.target.value)}
              />
              <CustomButton component="label">
                {soundSimilar?.name ? 'Eklendi' : 'Ekle'}
                <input
                  type="file"
                  hidden
                  accept=".mp3,audio/*"
                  onChange={(e) => {
                    const file = e.target.files[0];
                    const blob = file && file?.slice(0, file.size, file.type);
                    const newFile = file && new File([blob], `reading.${file?.name?.split('.')?.pop()}`, { type: file.type });
                    setSoundSimilar(newFile);
                  }}
                />
              </CustomButton>
            </div>
            <audio controls={soundSimilar} style={{ width: '100%', marginTop: 5 }}>
              <track kind="captions" {...props} />
              {/* <source src={soundSimilar && URL.createObjectURL(new Audio(soundSimilar))} type="audio/mpeg" /> */}
              {
                edit ? <source key={soundSimilar} src={soundSimilar} type="audio/mpeg" /> : <source src="https://egitim.hafizamerkezi.com/files/kelimeler/ses/10825.mp3" type="audio/mpeg" />
              }
              Your browser dosent support audio
            </audio>
          </Grid>
          <Grid item lg={4} md={6} sm={12} xs={12}>
            <div className="itemWrapper">
              <p>{(example && translate && exampleSound) ? 'Örnek Seçildi' : 'Örnek Seçilmedi'}</p>
              <CustomButton purple onClick={() => setExampleDialogOpen(true)}>Seç</CustomButton>
            </div>
          </Grid>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <div className="buttonWrapper">
              <CustomButton onClick={() => window.location.replace('#/Words')} lined>
                Geri
              </CustomButton>
              <CustomButton purple onClick={() => saveFunc()}>
                Kaydet
              </CustomButton>
            </div>
          </Grid>
        </Grid>
        {
          openInfoModal && (
            <Info
              open={openInfoModal}
              type="Warning"
              title={edit ? 'Kelime Düzenleniyor' : 'Yeni Kelime Ekleniyor'}
              text="Kelimeler kısmından tekrar düzenleyebilirsiniz."
              cancelButton={(<CustomButton lined onClick={() => setOpenInfoModal(false)}>Vazgeç</CustomButton>)}
              confirmButton={(<CustomButton purple onClick={() => { saveFunc(); setOpenInfoModal(false); }}>Tamam</CustomButton>)}
            />
          )
        }
      </PageWrapper>
      {
        fileDialogOpen && (
          <Dialog
            open={fileDialogOpen}
            // onClose={() => setLangDialogOpen(false)}
          >
            <CustomDialog>
              <h2>Dosya Seç</h2>
              <div className="content">
                <div className="fileInputWrapper">
                  <TextField
                    label="Resim "
                    fullWidth
                    variant="standard"
                    value={image?.name || ''}
                    disabled
                    InputLabelProps={{ focused: true, shrink: true }}
                    onChange={e => setSoundSimilar(e.target.value)}
                  />
                  <CustomButton component="label">
                    {image?.name ? 'Eklendi' : 'Ekle'}
                    <input
                      type="file"
                      hidden
                      onChange={(e) => {
                        const file = e.target.files[0];
                        const blob = file && file?.slice(0, file.size, file.type);
                        const newFile = file && new File([blob], `image.${file?.name?.split('.')?.pop()}`, { type: file.type });
                        setImage(newFile);
                      }}
                    />
                  </CustomButton>
                </div>
                <div className="fileInputWrapper">
                  <TextField
                    label="Okunuş Sesi"
                    fullWidth
                    variant="standard"
                    value={sound?.name || ''}
                    disabled
                    InputLabelProps={{ focused: true, shrink: true }}
                    onChange={e => setSoundSimilar(e.target.value)}
                  />
                  <CustomButton component="label">
                    {sound?.name ? 'Eklendi' : 'Ekle'}
                    <input
                      type="file"
                      hidden
                      onChange={(e) => {
                        const file = e.target.files[0];
                        const blob = file && file?.slice(0, file.size, file.type);
                        const newFile = file && new File([blob], `memory.${file?.name?.split('.')?.pop()}`, { type: file.type });
                        setSound(newFile);
                      }}
                    />
                  </CustomButton>
                </div>
                <div className="fileInputWrapper">
                  <TextField
                    label="Benzer Sesi"
                    fullWidth
                    variant="standard"
                    value={soundSimilar?.name || ''}
                    disabled
                    InputLabelProps={{ focused: true, shrink: true }}
                    onChange={e => setSoundSimilar(e.target.value)}
                  />
                  <CustomButton component="label">
                    {soundSimilar?.name ? 'Eklendi' : 'Ekle'}
                    <input
                      type="file"
                      hidden
                      onChange={(e) => {
                        const file = e.target.files[0];
                        const blob = file && file?.slice(0, file.size, file.type);
                        const newFile = file && new File([blob], `reading.${file?.name?.split('.')?.pop()}`, { type: file.type });
                        setSoundSimilar(newFile);
                      }}
                    />
                  </CustomButton>
                </div>
                <div className="buttonWrapper">
                  <CustomButton purple onClick={() => cancelFiles()}>Vazgeç</CustomButton>
                  <CustomButton purple onClick={() => setFileDialogOpen(false)}>Kaydet</CustomButton>
                </div>
              </div>
            </CustomDialog>
          </Dialog>
        )
      }
      {
        exampleDialogOpen && (
          <Dialog
            open={exampleDialogOpen}
            // onClose={() => setLangDialogOpen(false)}
          >
            <CustomDialog>
              <h2>Örnek Seç</h2>
              <div className="content">
                <TextField
                  label="Örnek"
                  fullWidth
                  variant="standard"
                  value={example}
                  onChange={e => setExample(e.target.value)}
                />
                <TextField
                  label="Çevirisi"
                  fullWidth
                  variant="standard"
                  value={translate}
                  onChange={e => setTranslate(e.target.value)}
                />
                <div className="fileInputWrapper">
                  <TextField
                    label="Örnek Sesi"
                    fullWidth
                    variant="standard"
                    value={exampleSound?.name || ''}
                    disabled
                    InputLabelProps={{ focused: true, shrink: true }}
                    onChange={e => setExampleSound(e.target.value)}
                  />
                  <CustomButton component="label">
                    {exampleSound?.name ? 'Eklendi' : 'Ekle'}
                    <input
                      type="file"
                      hidden
                      onChange={(e) => {
                        const file = e.target.files[0];
                        const blob = file && file?.slice(0, file.size, file.type);
                        const newFile = file && new File([blob], `reading.${file?.name?.split('.')?.pop()}`, { type: file.type });
                        setExampleSound(newFile);
                      }}
                    />
                  </CustomButton>
                </div>
                <div className="buttonWrapper">
                  <CustomButton purple onClick={() => cancelExample()}>Vazgeç</CustomButton>
                  <CustomButton purple onClick={() => saveExample()}>Kaydet</CustomButton>
                </div>
              </div>
            </CustomDialog>
          </Dialog>
        )
      }
    </>
  );
}

CreateWord.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  selectedWordId: PropTypes.number.isRequired,
  edit: PropTypes.bool,
  wordData: PropTypes.object,
  categoryData: PropTypes.array.isRequired,
  categorySubData: PropTypes.array.isRequired,
  activeLangId: PropTypes.number.isRequired,
  activeCatId: PropTypes.number.isRequired,
  activeCatSubId: PropTypes.number.isRequired,
};

CreateWord.defaultProps = {
  edit: false,
  wordData: {},
};
