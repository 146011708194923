import React, { useState, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Backdrop,
  Dialog,
  FormControlLabel,
  Modal,
  Switch,
  TextField,
} from '@material-ui/core';
import { Close } from '@material-ui/icons';
import PropTypes from 'prop-types';
import {
  CustomButton,
  CustomDialog,
  CustomFade,
  CustomIconButton,
} from '../../assets/styled';
import Info from './Info';
import * as thunks from '../../store/thunks';
import { setToast } from '../../store/actions';
import TransferList from '../TransferList';

export default function NewPackage(props) {
  const [openInfoModal, setOpenInfoModal] = useState(false);
  const [langDialogOpen, setLangDialogOpen] = useState(false);
  const [propertyDialogOpen, setPropertyDialogOpen] = useState(false);

  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [price, setPrice] = useState('');
  const [period, setPeriod] = useState('');
  const [image, setImage] = useState('');

  const [single, setSingle] = useState(true);
  const [featured, setFeatured] = useState(false);

  const [properties1, setProperties1] = useState('');
  const [properties2, setProperties2] = useState('');
  const [properties3, setProperties3] = useState('');
  const [properties4, setProperties4] = useState('');
  const [properties5, setProperties5] = useState('');
  const [selectedLangList, setSelectedLangList] = useState([]);
  const [unSelectedLangList, setUnSelectedLangList] = useState([]);

  const Languages = [];

  const datas = {
    name,
    description,
    price,
    period,
    image: 'https://thispersondoesnotexist.com/image',
    featured,
    is_single: single,
    PackageProperties: [
      // {
      //   name: 'PackagePropertiesname',
      // },
    ],
    Languages,
  };

  // Redux Store Verisi çağırma
  const allLanguages = useSelector(state1 => state1.allLanguages?.filter(i => i.is_removed === false));

  let allLanguagesHook;

  const dispatch = useDispatch();
  const createPackage = useCallback(() => dispatch(thunks.createPackage(datas)), [dispatch, datas]);
  const editPackage = useCallback(() => dispatch(thunks.editPackage(datas, props.packageData?.id)), [dispatch, datas, props.packageData?.id]);
  const showToast = (msg, type) => dispatch(setToast(true, msg, type));

  useEffect(() => {
    if (!(allLanguages?.length > 0)) {
      dispatch(thunks.getLanguages());
    }
    allLanguagesHook = [...allLanguages];
  }, [dispatch, allLanguages]);

  useEffect(() => {
    if (props.edit && props.packageData && !name) {
      const data = props.packageData;
      setName(data.name);
      setDescription(data.description);
      setPrice(data.price);
      setPeriod(data.period);
      setImage(data.image);
      setFeatured(data.featured);
      setSingle(data.is_single);
      setImage({ name: data.image });
      if (data.PackageProperties?.length > 0) {
        const x = data.PackageProperties;
        const y = x?.map((p, i) => (
          i === 0 ? setProperties1(p.name) : i === 1 ? setProperties2(p.name) : i === 2 ? setProperties3(p.name) : i === 3 ? setProperties4(p.name) : i === 4 && setProperties5(p.name)
        ));
      }
      if (data.Language?.length > 0 && allLanguages) {
        const x = data.Language;
        const z = allLanguagesHook;
        setSelectedLangList(x);
        z.map((d, i) => (
          x.map(l => (
            d.id === l.id && allLanguagesHook.splice(i, 1)
          ))
        ));
        setUnSelectedLangList(allLanguagesHook);
      }
    }
  }, [props.packageData, props.edit, name, allLanguagesHook, allLanguages]);

  function saveFunc() {
    if (name?.length > 0 && parseInt(price, 10) > 0 && period?.length > 0) {
      setOpenInfoModal(true);
    } else {
      showToast('Alanları doldurun!', 'warning');
    }
  }

  function enterInput(e) {
    if (e.key === 'Enter') {
      saveFunc();
    }
  }

  function cancelProperties() {
    if (!props.edit) {
      setProperties1('');
      setProperties2('');
      setProperties3('');
      setProperties4('');
      setProperties5('');
    }
    setPropertyDialogOpen(false);
  }

  function cancelLang() {
    if (!props.edit && selectedLangList.length === 0) {
      setSelectedLangList([]);
      setUnSelectedLangList([]);
    }
  }

  function runService() {
    for (let index = 1; index <= 5; index++) {
      // const x = datas.PackageProperties?.push({ name: [`${properties}${index + 1}`] });
      if (properties1?.length > 1 && index === 1) {
        const x = datas.PackageProperties?.push({ name: properties1 });
      }
      if (properties2?.length > 1 && index === 2) {
        const x = datas.PackageProperties?.push({ name: properties2 });
      }
      if (properties3?.length > 1 && index === 3) {
        const x = datas.PackageProperties?.push({ name: properties3 });
      }
      if (properties4?.length > 1 && index === 4) {
        const x = datas.PackageProperties?.push({ name: properties4 });
      }
      if (properties5?.length > 1 && index === 5) {
        const x = datas.PackageProperties?.push({ name: properties5 });
      }
    }
    // if (!single) {
    const x = selectedLangList?.map(l => Languages?.push({ language_id: l?.id }));
    // }
    if (props.edit) {
      editPackage();
    } else {
      createPackage();
    }
  }

  const propertiesAll = [properties1, properties2, properties3, properties4, properties5].filter(Boolean);

  return (
    <>
      <Modal
        open={props.open}
        onClose={() => props.onClose()}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <CustomFade in={props.open} onKeyDown={e => enterInput(e)}>
          <div>
            <div className="header">
              <h1>{props.edit ? 'Paketi Düzenle' : 'Yeni Paket Ekle'}</h1>
              <CustomIconButton onClick={() => props.onClose()}><Close /></CustomIconButton>
            </div>
            <div className="content">
              <div className="form">
                <TextField
                  label="Paket İsmi"
                  fullWidth
                  variant="standard"
                  value={name}
                  autoFocus
                  onChange={e => setName(e.target.value)}
                />
                <TextField
                  label="Paket Tanımı"
                  fullWidth
                  variant="standard"
                  value={description}
                  onChange={e => setDescription(e.target.value)}
                />
                <TextField
                  label="Fiyat"
                  fullWidth
                  variant="standard"
                  value={price}
                  onChange={e => setPrice(e.target.value)}
                />
                <TextField
                  label="Periyot"
                  fullWidth
                  variant="standard"
                  value={period}
                  onChange={e => setPeriod(e.target.value)}
                />
                <div className="fileInputWrapper">
                  <TextField
                    label="Resim "
                    fullWidth
                    variant="standard"
                    value={image?.name || ''}
                    disabled
                    InputLabelProps={{ focused: true, shrink: true }}
                    // onChange={e => setSoundSimilar(e.target.value)}
                  />
                  <CustomButton component="label">
                    {image?.name ? 'Eklendi' : 'Ekle'}
                    <input
                      type="file"
                      hidden
                      onChange={(e) => {
                        const file = e.target.files[0];
                        const blob = file && file?.slice(0, file.size, file.type);
                        const newFile = file && new File([blob], `image.${file?.name?.split('.')?.pop()}`, { type: file.type });
                        setImage(newFile);
                      }}
                    />
                  </CustomButton>
                </div>
                <div className="itemWrapper">
                  <FormControlLabel
                    control={(
                      <Switch
                        checked={featured}
                        onChange={() => setFeatured(!featured)}
                        name="isproperties"
                        color="primary"
                      />
                    )}
                    label={featured ? 'Öne Çıkan' : 'Öne Çıkartma'}
                  />
                </div>
                <div className="itemWrapper">
                  <p>{(properties1 || properties2 || properties3 || properties4 || properties5) ? `${propertiesAll.length} Özellik Seçildi` : 'Özellikleri Seçilmedi'}</p>
                  <CustomButton purple onClick={() => setPropertyDialogOpen(true)}>Seç</CustomButton>
                </div>
                <div className="itemWrapper">
                  <FormControlLabel
                    control={(
                      <Switch
                        checked={single}
                        onChange={() => { setSingle(!single); setSelectedLangList([]); }}
                        name="isSingle"
                        color="primary"
                      />
                    )}
                    label={single ? 'Tekli' : `${selectedLangList.length} dil seçildi`}
                  />
                  {/* <CustomButton purple disabled={single} onClick={() => setLangDialogOpen(true)}>Dil Seç</CustomButton> */}
                  <CustomButton purple onClick={() => setLangDialogOpen(true)}>Dil Seç</CustomButton>
                </div>
                <div className="buttonWrapper">
                  <CustomButton onClick={() => props.onClose()} lined>
                    Vazgeç
                  </CustomButton>
                  <CustomButton purple onClick={() => saveFunc()}>
                    Kaydet
                  </CustomButton>
                </div>
              </div>
            </div>
            {
            openInfoModal && (
              <Info
                open={openInfoModal}
                type="Warning"
                title={props.edit ? 'Paket Düzenleniyor' : 'Yeni Paket Ekleniyor'}
                text="Paketler kısmından tekrar düzenleyebilirsiniz."
                cancelButton={(<CustomButton lined onClick={() => setOpenInfoModal(false)}>Vazgeç</CustomButton>)}
                confirmButton={(<CustomButton purple onClick={() => { runService(); props.onClose(); setOpenInfoModal(false); }}>Tamam</CustomButton>)}
              />
            )
          }
          </div>
        </CustomFade>
      </Modal>
      {
        langDialogOpen && (
          <Dialog
            open={langDialogOpen}
            // onClose={() => setLangDialogOpen(false)}
          >
            <CustomDialog>
              <h2>Dil Seç</h2>
              <div>
                <TransferList
                  data={allLanguages?.filter?.(l => !l.is_removed)}
                  savedRightData={selectedLangList}
                  rightList={selectedLang => setSelectedLangList(selectedLang, selectedLang?.length > 1 && setSingle(false))}
                  savedLeftData={unSelectedLangList}
                  leftList={unSelectedLang => setUnSelectedLangList(unSelectedLang)}
                  onClose={() => setLangDialogOpen(false)}
                  cancelLang={() => cancelLang()}
                  edit={props.edit}
                />
              </div>
            </CustomDialog>
          </Dialog>
        )
      }
      {
        propertyDialogOpen && (
          <Dialog
            open={propertyDialogOpen}
            // onClose={() => setLangDialogOpen(false)}
          >
            <CustomDialog>
              <h2>Özellik Seç</h2>
              <div className="content">
                <TextField
                  label="Özellik İsmi 1"
                  fullWidth
                  variant="standard"
                  value={properties1}
                  autoFocus
                  onChange={e => setProperties1(e.target.value)}
                />
                <TextField
                  label="Özellik İsmi 2"
                  fullWidth
                  variant="standard"
                  value={properties2}
                  onChange={e => setProperties2(e.target.value)}
                />
                <TextField
                  label="Özellik İsmi 3"
                  fullWidth
                  variant="standard"
                  value={properties3}
                  onChange={e => setProperties3(e.target.value)}
                />
                <TextField
                  label="Özellik İsmi 4"
                  fullWidth
                  variant="standard"
                  value={properties4}
                  onChange={e => setProperties4(e.target.value)}
                />
                <TextField
                  label="Özellik İsmi 5"
                  fullWidth
                  variant="standard"
                  value={properties5}
                  onChange={e => setProperties5(e.target.value)}
                />
                <div className="buttonWrapper">
                  <CustomButton purple onClick={() => cancelProperties()}>Vazgeç</CustomButton>
                  <CustomButton purple onClick={() => setPropertyDialogOpen(false)}>Kaydet</CustomButton>
                </div>
              </div>
            </CustomDialog>
          </Dialog>
        )
      }
    </>
  );
}

NewPackage.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  // selectedCategoryId: PropTypes.number.isRequired,
  edit: PropTypes.bool,
  packageData: PropTypes.object,
};

NewPackage.defaultProps = {
  edit: false,
  packageData: {},
};
