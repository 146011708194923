import React, { useCallback, useEffect, useState } from 'react';
import { Grid, InputAdornment, TextField } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import {
  Cancel, CheckCircle, Close, MoreVert, Search,
} from '@material-ui/icons';
import {
  CustomButton,
  CustomIconButton,
  MobileMoreMenu,
  MobileMoreMenuItem,
  PageWrapper, TabbleStatusBox,
} from '../assets/styled';
import Table from '../components/Table';
import * as thunks from '../store/thunks';
import { dateTool } from '../commons/utils';
import { Edit, Plus } from '../assets/icons';
import NewCoupon from '../components/modals/NewCoupon';

function Coupons() {
  const [state, setState] = React.useState({
    // searchFilter: '',
  });
  const [searchFilter, setSearchFilter] = useState('');
  const [filterType, setFilterType] = useState('all');
  const [activeFilter, setActiveFilter] = useState(1);

  const [selectedCouponId, setSelectedCouponId] = useState(null);
  const [openCouponModal, setOpenCouponModal] = useState(false);
  const [edit, setEdit] = useState(false);

  // Redux Store Verisi çağırma
  const allCoupon = useSelector(state1 => state1.allCoupon);
  const allPackage = useSelector(state1 => state1.allPackage);


  // Action Listesi
  const dispatch = useDispatch();

  const deleteCoupon = useCallback(() => dispatch(thunks.deleteCoupon(selectedCouponId)), [dispatch, selectedCouponId]);


  // Fonksiyon Direkt Çalışacaksa
  useEffect(() => {
    dispatch(thunks.getCoupon());
    dispatch(thunks.getPackage());
  }, [dispatch]);

  const columns = [
    {
      name: 'is_removed',
      text: 'Durumu',
      filterable: true,
      editable: false,
      align: 'left',
      sortable: true,
      type: 'text',
      customBody: value => (
        <TabbleStatusBox status={value ? 2 : 3}>
          {value ? 'Pasif' : 'Aktif'}
        </TabbleStatusBox>
      ),
    },
    {
      name: 'id',
      text: 'Id',
      filterable: true,
      editable: false,
      align: 'center',
      sortable: true,
      type: 'text',
    },
    {
      name: 'name',
      text: 'İsim',
      filterable: false,
      editable: false,
      align: 'left',
      sortable: true,
      type: 'text',
    },
    {
      name: 'code',
      text: 'Kupon Kodu',
      filterable: false,
      editable: false,
      align: 'left',
      sortable: true,
      type: 'text',
    },
    {
      name: 'discount',
      text: 'İndirim',
      filterable: false,
      editable: false,
      align: 'left',
      sortable: true,
      type: 'text',
      customBody: value => (
        <span>%{value}</span>
      ),
    },
    {
      name: 'package_id',
      text: 'Paket',
      filterable: false,
      editable: false,
      align: 'left',
      sortable: true,
      type: 'text',
      customBody: value => (
        <span>{allPackage?.find(p => p.id === value)?.name || '-'}</span>
      ),
    },
    {
      name: 'start_date',
      text: 'Başlangıç Tarihi',
      filterable: false,
      editable: false,
      align: 'left',
      sortable: true,
      type: 'text',
      customBody: value => (
        <div>
          {dateTool(value)}
        </div>
      ),
    },
    {
      name: 'end_date',
      text: 'Bitiş Tarihi',
      filterable: false,
      editable: false,
      align: 'left',
      sortable: true,
      type: 'text',
      customBody: value => (
        <div>
          {dateTool(value)}
        </div>
      ),
    },
    {
      name: 'createdAt',
      text: 'Oluşturma Tarihi',
      filterable: false,
      editable: false,
      align: 'left',
      sortable: true,
      type: 'text',
      customBody: value => (
        <div>
          {dateTool(value)}
        </div>
      ),
    },
    {
      name: 'updatedAt',
      text: 'Güncelleme Tarihi',
      filterable: false,
      editable: false,
      align: 'left',
      sortable: true,
      type: 'text',
      customBody: value => (
        <div>
          {dateTool(value)}
        </div>
      ),
    },
    {
      name: 'Action',
      text: 'Action',
      filterable: false,
      editable: false,
      align: 'left',
      sortable: false,
      type: 'action',
      disableSetting: false,
      customBody: (value, columnName, index, row) => (
        <div id="tableButton">
          <CustomIconButton onClick={(e) => { setState({ [`anchorMobileTable${row.id}`]: e.currentTarget }); setSelectedCouponId(row.id); }}>
            {/* <MobileTripleIcon /> */}
            <MoreVert />
          </CustomIconButton>
          {
            state[`anchorMobileTable${row.id}`] && (
              <MobileMoreMenu
                id="mobileMoreMenu"
                anchorEl={state[`anchorMobileTable${row.id}`]}
                keepMounted
                open={Boolean(state[`anchorMobileTable${row.id}`])}
                onClose={() => { setSelectedCouponId(null); setEdit(false); setState({ [`anchorMobileTable${row.id}`]: null }); }}
                // placement="left"
                anchorOrigin={{ horizontal: 'left', vertical: 'center' }}
                transformOrigin={{ horizontal: 'right', vertical: 'center' }}
                disablePortal={false}
                modifiers={{
                  flip: {
                    enabled: false,
                  },
                  preventOverflow: {
                    enabled: true,
                    boundariesElement: 'scrollParent',
                  },
                  arrow: {
                    enabled: true,
                    element: state[`anchorMobileTable${row.id}`],
                  },
                }}
              >
                <MobileMoreMenuItem
                  // active={!state[`columnVisibility${tabValue}`]}
                  onClick={() => { setState({ [`anchorMobileTable${row.id}`]: null }); setEdit(true); setOpenCouponModal(true); }}
                >
                  <Edit />
                  Kuponu Düzenle
                </MobileMoreMenuItem>
                <MobileMoreMenuItem
                  // active={!state[`columnVisibility${tabValue}`]}
                  onClick={() => { deleteCoupon(); setState({ infoDeleteModal: true, [`anchorMobileTable${row.id}`]: null }); }}
                >
                  {row.is_removed ? <CheckCircle /> : <Cancel />}
                  {row.is_removed ? 'Aktif Yap' : 'Pasif Yap'}
                </MobileMoreMenuItem>
              </MobileMoreMenu>
            )
          }
        </div>
      ),
    },
  ];

  const filteredActive = filterType !== 'all' ? allCoupon?.filter(item => item.is_removed?.toString() === filterType) : allCoupon;
  const lowercasedFilter = searchFilter?.length > 0 && searchFilter?.toLocaleUpperCase();
  const filteredData = searchFilter.length > 0 ? (filteredActive && filteredActive?.filter(item => Object?.keys(item)?.some(key => typeof (item[(key)]) === 'string'
  && item[(key)]?.toLocaleUpperCase()?.includes(lowercasedFilter)))) : filteredActive;

  return (
    <PageWrapper>
      <Grid container spacing="3">
        <Grid item lg={12} xs={12}>
          <div className="pageHeader">
            <TextField
              // label="Ara"
              placeholder="Tablo'da ara"
              variant="standard"
              InputProps={{
                startAdornment: <InputAdornment position="start"><Search color="primary" /></InputAdornment>,
                endAdornment: searchFilter?.length > 0 && <InputAdornment position="end"><CustomIconButton noPad onClick={() => setSearchFilter('')}><Close color="primary" /></CustomIconButton></InputAdornment>,
              }}
              value={searchFilter}
              onChange={e => setSearchFilter(e.target.value)}
            />
            <CustomButton purple onClick={() => { setEdit(false); setOpenCouponModal(true); setSelectedCouponId(null); }} startIcon={<Plus />}>Kupon Ekle</CustomButton>
          </div>
        </Grid>
        <Grid item lg={12} xs={12}>
          <div className="filterButtons">
            <CustomButton orange={activeFilter === 1} purple onClick={() => { setActiveFilter(1); setFilterType('all'); }}>Tümü</CustomButton>
            <CustomButton orange={activeFilter === 2} purple onClick={() => { setActiveFilter(2); setFilterType('false'); }}>Aktif Olan</CustomButton>
            <CustomButton orange={activeFilter === 3} purple onClick={() => { setActiveFilter(3); setFilterType('true'); }}>Pasif Olan</CustomButton>
          </div>
        </Grid>
        <Grid item lg={12} xs={12}>
          <Table
            data={filteredData || []}
            columns={columns || []}
            id="reportsTable"
            options={{
              selectableRows: 'none',
              header: {
                visible: true,
                columnSetting: false,
              },
              filter: {
                show: true,
                filters: [],
                // onChange: (state, value, text, type) => console.log(state, value, text, type),
                // drawerChange: () => setState(prevstate => ({ filterDrawer: !prevstate.filterDrawer })),
              },
              pagination: {
                active: true,
                perPage: 20,
              },
            }}
            // rowClick={row => console.log(row)}
            // rowClick={() => window.location.replace('/SuggestionDetail')}
          />
        </Grid>
      </Grid>
      {
        openCouponModal && (
          <NewCoupon
            open={openCouponModal}
            onClose={() => setOpenCouponModal(false)}
            edit={edit}
            selectedCouponId={selectedCouponId}
            couponData={allCoupon.find(select => select.id === selectedCouponId)}
          />

        )
      }
    </PageWrapper>
  );
}

export default Coupons;
