import React, { useCallback, useEffect } from 'react';
import { Grid } from '@material-ui/core';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { Edit } from '@material-ui/icons';
import {
  CustomButton, CustomIconButton, PageWrapper, Simplelink,
} from '../../assets/styled';
import Table from '../../components/Table';
import * as thunks from '../../store/thunks';

function Blogs() {
  const dispatch = useDispatch();
  const getBlogsService = useCallback(() => dispatch(thunks.getBlogs()), [dispatch]);

  const blogsData = useSelector(state1 => state1.blogs);

  useEffect(() => {
    getBlogsService();
  }, [getBlogsService]);

  const columns = [
    {
      name: 'id',
      text: 'ID',
      filterable: true,
      align: 'left',
      sortable: true,
      type: 'text',
    },
    {
      name: 'is_removed',
      text: 'Statü',
      filterable: true,
      align: 'left',
      sortable: true,
      type: 'text',
      customBody: value => (
        <span>{value ? 'Pasif' : 'Yayında'}</span>
      ),
    },
    {
      name: 'updatedAt',
      text: 'Güncelleme Tarihi',
      filterable: true,
      align: 'left',
      sortable: true,
      type: 'text',
      customBody: value => (
        <span>{moment(value).format('DD.MM.YYYY')}</span>
      ),
    },
    {
      name: 'title',
      text: 'Blog Başlığı',
      filterable: true,
      align: 'left',
      type: 'text',
    },
    {
      name: 'view_count',
      text: 'Görüntülenme',
      filterable: true,
      align: 'left',
      type: 'text',
    },
    {
      name: 'Action',
      text: 'Action',
      filterable: false,
      editable: false,
      align: 'left',
      sortable: false,
      type: 'action',
      disableSetting: false,
      customBody: (value, columnName, index, row) => (
        <div id="tableButton">
          <Simplelink to={`/Blog/${row.id}`}>
            <CustomIconButton><Edit /></CustomIconButton>
          </Simplelink>
        </div>
      ),
    },
  ];

  return (
    <PageWrapper>
      <Grid container spacing="3">
        <Grid item lg={12} xs={12}>
          <h1 style={{ color: 'white' }}>Blogları Görüntüle, düzenle ve yarat</h1>
        </Grid>
        <Grid item lg={12} xs={12}>
          <Simplelink to="/NewBlog">
            <CustomButton component="label">Yeni Blog</CustomButton>
          </Simplelink>
        </Grid>
        <Grid item lg={12} xs={12}>
          <Table
            data={blogsData || []}
            columns={columns || []}
            id="blogsTable"
            options={{
              selectableRows: 'none',
              header: {
                visible: true,
                columnSetting: false,
              },
              filter: {
                show: true,
                filters: [],
                // onChange: (state, value, text, type) => console.log(state, value, text, type),
                // drawerChange: () => setState(prevstate => ({ filterDrawer: !prevstate.filterDrawer })),
              },
              pagination: {
                active: true,
                perPage: 20,
              },
            }}
          />
        </Grid>
      </Grid>
    </PageWrapper>
  );
}

export default Blogs;
