import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Backdrop,
  Chip,
  FormControl,
  Input,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  TextField,
} from '@material-ui/core';
import { Close } from '@material-ui/icons';
import PropTypes from 'prop-types';
import {
  CustomButton,
  CustomFade,
  CustomIconButton,
} from '../../assets/styled';
import * as thunks from '../../store/thunks';
import { setToast } from '../../store/actions';

export default function AssignPackage(props) {
  const [openInfoModal, setOpenInfoModal] = useState(false);
  const [selectedPackage, setSelectedPackage] = useState('');
  const [selectedLanguages, setSelectedLanguages] = useState([]);

  const packages = useSelector(state => state.allPackage);

  const dispatch = useDispatch();
  const showToast = (msg, type) => dispatch(setToast(true, msg, type));


  async function saveFunc() {
    if (selectedPackage && selectedLanguages.length > 0 && props.userData?.id) {
      const data = {
        package_id: selectedPackage,
        user_id: props.userData?.id,
        languages: selectedLanguages
      };
      const result = await dispatch(thunks.assignPackage(data));
      if (result?.status) {
        props.onClose();
      }
    } else {
      showToast('Alanları doldurun!', 'warning');
    }
  }

  return (
    <Modal
      open={props.open}
      onClose={() => props.onClose()}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <CustomFade in={props.open}>
        <div>
          <div className="header">
            <h1>Kullanıcıya Paket Ata</h1>
            <CustomIconButton onClick={() => props.onClose()}><Close /></CustomIconButton>
          </div>
          <div className="content">
            <h4 style={{ color: 'cornflowerblue', textAlign: 'center', padding: '25px 0 0 0' }}>{props?.userData?.name} için paket atıyorsunuz</h4>
            <div className="form">
              <TextField
                select
                fullWidth
                variant="standard"
                label="Paket Seçiniz"
                value={selectedPackage}
                onChange={e => {setSelectedPackage(e.target.value); setSelectedLanguages([]); }}
              >
                {packages?.filter(p => !p.is_removed)?.map(option => (
                  <MenuItem key={option.id} value={option.id}>
                    {option.name}
                  </MenuItem>
                ))}
              </TextField>
              <FormControl fullWidth>
                <InputLabel id="demo-mutiple-chip-label">Dil Seçiniz</InputLabel>
                <Select
                  labelId="demo-mutiple-chip-label"
                  id="demo-mutiple-chip"
                  multiple
                  value={selectedLanguages}
                  fullWidth
                  onChange={e => setSelectedLanguages(e.target.value)}
                  input={<Input id="select-multiple-chip" />}
                  renderValue={(selected) => (
                    <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                      {selected.map((value) => (
                        <Chip key={value} label={packages?.find(p => p.id === selectedPackage)?.Language.find(l => l.id === value)?.name} style={{ margin: 2 }} />
                      ))}
                    </div>
                  )}
                  // MenuProps={MenuProps}
                >
                  {packages?.find(p => p.id === selectedPackage)?.Language?.map((l) => (
                    <MenuItem key={l.id} value={l.id}>
                      {l.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <div className="buttonWrapper">
                <CustomButton onClick={() => props.onClose()} lined>
                  Vazgeç
                </CustomButton>
                <CustomButton purple onClick={() => saveFunc()}>
                  Kaydet
                </CustomButton>
              </div>
            </div>
          </div>
        </div>
      </CustomFade>
    </Modal>
  );
}

AssignPackage.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  selectedUserId: PropTypes.number.isRequired,
  userData: PropTypes.object,
};

AssignPackage.defaultProps = {
  edit: false,
  userData: {},
};
