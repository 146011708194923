/* eslint-disable react/prop-types */
import {
  Backdrop, MenuItem, Modal, TextField,
} from '@material-ui/core';
import React, { useCallback, useEffect, useState } from 'react';
import { Close } from '@material-ui/icons';
import { useDispatch, useSelector } from 'react-redux';
import { Autocomplete } from '@material-ui/lab';
import { CustomButton, CustomFade, CustomIconButton } from '../../assets/styled';
import * as thunks from '../../store/thunks';
// import { payment_types } from '../../commons/constants';

function PaymentNotification({ open, onClose }) {
  const [warningOpen, setWarningOpen] = useState(false);

  const [selectedUser, setSelectedUser] = useState(null);
  const [selectedPackage, setSelectedPackage] = useState(null);
  const [selectedLanguage, setSelectedLanguage] = useState([]);
  // const [paymentType, setPaymentType] = useState('DEBIT');
  const [isBulk, setIsBulk] = useState(0);
  const [price, setPrice] = useState(0);

  const dispatch = useDispatch();
  const createPayment = useCallback(data => dispatch(thunks.createPayment(data)), [dispatch]);

  const allUsers = useSelector(state1 => state1.allUsers);
  const allPackage = useSelector(state1 => state1.allPackage);
  const allLanguages = useSelector(state1 => state1.allLanguages);

  useEffect(() => {
    if (allUsers.length === 0) {
      dispatch(thunks.getUser());
      dispatch(thunks.getPackage());
      dispatch(thunks.getLanguages());
    }
  }, [dispatch]);

  const saveFunc = (checked) => {
    if (!checked) {
      setWarningOpen(true);
      return;
    }
    const data = {
      user_id: selectedUser.id,
      package_id: selectedPackage,
      languages: selectedLanguage.map(l => l.id),
      payment_type: 'DEBIT',
      price,
      is_bulk: isBulk,
    };
    createPayment(data);
    setWarningOpen(false);
    onClose();
  };

  useEffect(() => {
    if (selectedUser) {
      dispatch(thunks.getUserPaymentList(selectedUser.id));
    }
  }, [selectedUser, dispatch]);

  return (
    <>
      <Modal
        open={open}
        onClose={() => onClose()}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <CustomFade in={open}>
          <div>
            <div className="header">
              <h1>Ödeme Bildirimi Yarat</h1>
              <CustomIconButton onClick={() => onClose()}><Close /></CustomIconButton>
            </div>
            <div className="content">
              <div className="form">
                <Autocomplete
                  id="combo-box-demo"
                  options={allUsers.filter(user => !user.is_removed)}
                  getOptionLabel={option => `${option.name} - ${option.mail}`}
                  onChange={(e, value) => setSelectedUser(value)}
                  value={selectedUser}
                  style={{ width: '100%', color: 'white' }}
                  renderInput={params => <TextField {...params} variant="standard" style={{ width: '100%', color: 'white' }} label="Kullanıcı" />}
                />
                <TextField
                  select
                  fullWidth
                  variant="standard"
                  label="Paket Seçimi"
                  value={selectedPackage}
                  onChange={(e) => {
                    setSelectedPackage(e.target.value);
                    if (e.target.value !== 999) {
                      setSelectedLanguage(allPackage.find(p => p.id === e.target.value)?.Language || []);
                    } else {
                      setSelectedLanguage([]);
                    }
                    setPrice(allPackage.find(p => p.id === e.target.value)?.price);
                  }}
                >
                  {allPackage?.map(option => (
                    <MenuItem key={option.id} value={option.id}>
                      {option?.name}
                    </MenuItem>
                  ))}
                </TextField>
                <Autocomplete
                  multiple
                  id="tags-standard"
                  disabled={selectedPackage !== 999}
                  options={allLanguages?.filter(l => !l.is_removed)}
                  getOptionLabel={option => option.name}
                  onChange={(e, value) => setSelectedLanguage(value)}
                  value={selectedLanguage}
                  renderInput={params => (
                    <TextField
                      {...params}
                      variant="standard"
                      label="Dil Seçimi"
                      placeholder="Dil Seçimi"
                    />
                  )}
                />
                {/* <TextField
                  select
                  label="Ödeme Tipi"
                  fullWidth
                  variant="standard"
                  value={paymentType}
                  onChange={(e) => { setPaymentType(e.target.value); }}
                >
                  {payment_types?.map(option => (
                    <MenuItem key={option.id} value={option.id}>
                      {option.name}
                    </MenuItem>
                  ))}
                </TextField> */}
                <TextField
                  select
                  fullWidth
                  variant="standard"
                  label="Bu bir toplu Satış mı?"
                  value={isBulk}
                  onChange={e => setIsBulk(e.target.value)}
                >
                  <MenuItem key={1} value={1}>
                    Evet
                  </MenuItem>
                  <MenuItem key={0} value={0}>
                    Hayır
                  </MenuItem>
                </TextField>
                <TextField
                  fullWidth
                  variant="standard"
                  label="Fiyat"
                  type="number"
                  value={price}
                  onChange={e => setPrice(e.target.value)}
                />
                <div className="buttonWrapper">
                  <CustomButton onClick={() => onClose()} lined>
                    Vazgeç
                  </CustomButton>
                  <CustomButton purple onClick={() => saveFunc()}>
                    Kaydet
                  </CustomButton>
                </div>
              </div>
            </div>
          </div>
        </CustomFade>
      </Modal>
      {
        warningOpen && (
          <Modal
            open={warningOpen}
            onClose={() => setWarningOpen(false)}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
              timeout: 500,
            }}
          >
            <CustomFade in={open}>
              <div>
                <div className="header">
                  <h1>DİKKAT!</h1>
                  <CustomIconButton onClick={() => setWarningOpen(false)}><Close /></CustomIconButton>
                </div>
                <div className="content">
                  <div className="form" style={{ color: 'white' }}>
                    <p>Ödeme Bildirimi ekliyorsunuz.</p>
                    <p>Eğer onaylarsanız kullanıcı için Ödeme bilgisi oluşacaktır.</p>
                    <p>Onaylıyor musunuz?</p>
                    <div className="buttonWrapper">
                      <CustomButton onClick={() => setWarningOpen(false)} lined>
                        Vazgeç
                      </CustomButton>
                      <CustomButton purple onClick={() => saveFunc(true)}>
                        Ödemeyi Onayla
                      </CustomButton>
                    </div>
                  </div>
                </div>
              </div>
            </CustomFade>
          </Modal>
        )
      }
    </>
  );
}

export default PaymentNotification;
