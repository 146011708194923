import React, { useCallback, useState } from 'react';
import {
  FormControl, Grid, InputLabel, MenuItem, Select,
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { CustomButton, PageWrapper } from '../assets/styled';
import * as thunks from '../store/thunks';
import { setToast } from '../store/actions';

function HelpersPage() {
  const dispatch = useDispatch();
  const showToast = (msg, type) => dispatch(setToast(true, msg, type));
  const uploadfileService = useCallback((file, data) => dispatch(thunks.uploadFile(file, data)), [dispatch]);

  const [file, setFile] = useState(null);
  const [selectOpen, setSelectOpen] = useState(false);
  const [fileType, setFileType] = useState('');

  const uploadUrl = useSelector(state => state.lastUplaodFile);

  const upload = async () => {
    if (!file || !fileType) {
      showToast('Alanları doldurun!', 'warning');
    } else {
      uploadfileService(file, fileType);
    }
  };

  return (
    <PageWrapper>
      <div style={{ color: 'white' }}>
        <Grid container spacing="3">
          <Grid item lg={12} xs={12}>
            <h3 style={{ marginBottom: 15 }}>Dosya Yükleme</h3>
            <div style={{ display: 'flex', alignItems: 'center', marginBottom: 15 }}>
              <input type="file" onChange={e => setFile(e.target.files[0])} />
            </div>
            <div style={{ display: 'flex', alignItems: 'center', marginBottom: 15 }}>
              <FormControl>
                <InputLabel id="demo-controlled-open-select-label">Dosya Tipi</InputLabel>
                <Select
                  style={{ width: 200 }}
                  labelId="demo-controlled-open-select-label"
                  id="demo-controlled-open-select"
                  open={selectOpen}
                  fullWidth
                  onClose={() => setSelectOpen(false)}
                  onOpen={() => setSelectOpen(true)}
                  value={fileType}
                  onChange={e => setFileType(e.target.value)}
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  <MenuItem value="blog">Blog</MenuItem>
                  <MenuItem value="other">Diğer</MenuItem>
                </Select>
              </FormControl>
            </div>
            <CustomButton component="label" onClick={() => upload()}>Yükle</CustomButton>
          </Grid>
          <p>{uploadUrl}</p>
        </Grid>
      </div>
    </PageWrapper>
  );
}

export default HelpersPage;
