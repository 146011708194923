import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Grid, Button, Checkbox, List, ListItem, ListItemIcon, ListItemText, Paper,
} from '@material-ui/core';
import { CustomButton, TransferWrapper } from '../assets/styled';

function not(a, b) {
  return a.filter(value => b.indexOf(value) === -1);
}

function intersection(a, b) {
  return a.filter(value => b.indexOf(value) !== -1);
}

export default function TransferList(props) {
  const [checked, setChecked] = useState([]);
  const [left, setLeft] = useState([]);
  const [right, setRight] = useState([]);

  useEffect(() => {
    if (!(left?.length > 0) && props.savedRightData?.length === 0 && right?.length === 0) {
      setLeft(props.data);
    }
  }, [props.data, left]);

  useEffect(() => {
    if (!(right?.length > 0) && props.savedRightData?.length > 0 && left?.length < props.data.length) {
      setRight(props.savedRightData);
    }
  }, [props.savedRightData, right]);

  useEffect(() => {
    if (!(left?.length > 0) && props.savedLeftData?.length > 0 && right?.length < props.data.length && props.savedRightData?.length > 0) {
      setLeft(props.savedLeftData);
    }
  }, [props.savedLeftData, left]);

  function onChange() {
    props.rightList(right);
    props.leftList(left);
  };

  const leftChecked = intersection(checked, left);
  const rightChecked = intersection(checked, right);

  const handleToggle = value => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }
    setChecked(newChecked);
  };

  const handleAllRight = () => {
    setRight(right.concat(left));
    setLeft([]);
  };

  const handleCheckedRight = () => {
    setRight(right.concat(leftChecked));
    setLeft(not(left, leftChecked));
    setChecked(not(checked, leftChecked));
  };

  const handleCheckedLeft = () => {
    setLeft(left.concat(rightChecked));
    setRight(not(right, rightChecked));
    setChecked(not(checked, rightChecked));
  };

  const handleAllLeft = () => {
    setLeft(left.concat(right));
    setRight([]);
  };

  function cancelLang() {
    setLeft([]);
    setRight([]);
    props.onClose();
    props.cancelLang();
  }

  const customList = items => (
    <Paper style={{ height: '100%', minWidth: 200 }}>
      <List dense component="div" role="list">
        {items && items?.map((value) => {
          const labelId = `transfer-list-item-${value?.id}-label`;

          return (
            <ListItem
              key={value?.id}
              role="listitem"
              button
              onClick={handleToggle(value)}
            >
              <ListItemIcon>
                <Checkbox
                  checked={checked.indexOf(value) !== -1}
                  tabIndex={-1}
                  disableRipple
                  inputProps={{
                    'aria-labelledby': labelId,
                  }}
                />
              </ListItemIcon>
              <ListItemText id={labelId} primary={value?.name} />
            </ListItem>
          );
        })}
        <ListItem />
      </List>
    </Paper>
  );

  return (
    <TransferWrapper>
      <Grid container spacing={2} alignItems="stretch">
        <Grid item lg={5} md={5} sm={5} xs={12}>{customList(left)}</Grid>
        <Grid item lg={2} md={2} sm={2} xs={12}>
          <Grid container direction="column" alignItems="center" justifyContent="space-evenly" style={{ height: '100%' }}>
            <Button
              variant="outlined"
              size="small"
              onClick={handleAllRight}
              disabled={left.length === 0}
              aria-label="move all right"
            >
              ≫
            </Button>
            <Button
              variant="outlined"
              size="small"
              onClick={handleCheckedRight}
              disabled={leftChecked.length === 0}
              aria-label="move selected right"
            >
              &gt;
            </Button>
            <Button
              variant="outlined"
              size="small"
              onClick={handleCheckedLeft}
              disabled={rightChecked.length === 0}
              aria-label="move selected left"
            >
              &lt;
            </Button>
            <Button
              variant="outlined"
              size="small"
              onClick={handleAllLeft}
              disabled={right.length === 0}
              aria-label="move all left"
            >
              ≪
            </Button>
            <CustomButton
              purple
              size="small"
              onClick={() => { onChange(); props.onClose(); }}
              disabled={right.length === 0}
            >
              Seç
            </CustomButton>
            <CustomButton
              lined
              size="small"
              onClick={() => cancelLang()}
            >
              Vazgeç
            </CustomButton>
          </Grid>
        </Grid>
        <Grid item lg={5} md={5} sm={5} xs={12}>{customList(right)}</Grid>
      </Grid>
    </TransferWrapper>
  );
}

TransferList.propTypes = {
  data: PropTypes.array.isRequired,
  rightList: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  savedRightData: PropTypes.array.isRequired,
  leftList: PropTypes.func.isRequired,
  savedLeftData: PropTypes.array.isRequired,
  cancelLang: PropTypes.func.isRequired,
  edit: PropTypes.bool.isRequired,
};

TransferList.defaultProps = {
};
