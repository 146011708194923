import React from 'react';
import PropTypes from 'prop-types';
import { Backdrop, Modal } from '@material-ui/core';
import {
  // CustomButton,
  CustomFadeInfo,
} from '../../assets/styled';
import {
  ConfirmInfo,
  DeleteInfo,
  InfoInfo,
  QuestionInfo,
  WarningInfo,
} from '../../assets/icons';
import { Colors } from '../../assets/statics';

const types = {
  Success: {
    icon: (<ConfirmInfo />),
    color: Colors.green,
  },
  Warning: {
    icon: (<WarningInfo />),
    color: Colors.orange,
  },
  Info: {
    icon: (<InfoInfo />),
    color: Colors.blue,
  },
  Error: {
    icon: (<DeleteInfo />),
    color: Colors.burgundy,
  },
  Question: {
    icon: (<QuestionInfo />),
    color: Colors.blueLight2,
  },
};

export default function Info(props) {
  return (
    <Modal
      open={props.open}
      onClose={() => {}}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <CustomFadeInfo in={props.open}>
        <div>
          <div className="content">
            {
              props.type && (
                <>
                  {types[props.type].icon}
                  <h2 style={{ color: types[props.type].color, textAlign: 'center' }}>{props.title}</h2>
                  <p>{props.text}</p>
                  <div className="buttonWrapper">
                    {props.cancelButton}
                    {props.confirmButton}
                  </div>
                </>
              )
            }
          </div>
        </div>
      </CustomFadeInfo>
    </Modal>
  );
}

Info.propTypes = {
  open: PropTypes.bool.isRequired,
  // onClose: PropTypes.func.isRequired,
  type: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  cancelButton: PropTypes.any.isRequired,
  confirmButton: PropTypes.any.isRequired,
};
