/* eslint-disable react/prop-types */
import React from 'react';
import { ResponsivePie } from '@nivo/pie';
import { Colors } from '../../assets/statics';

// 1 = ios, 2 = android, 3 = web

function Pie({ statics }) {
  const data = [
    {
      id: 'Kredi_Kartı',
      label: 'Kredi_Kartı',
      value: statics?.filter?.(item => item.Payment?.payment_type === 'CREDIT')?.length || 0,
      color: 'hsl(149, 70%, 50%)',
    },
    {
      id: 'Havale',
      label: 'Havale',
      value: statics?.filter?.(item => item.Payment?.payment_type === 'DEBIT')?.length || 0,
      color: 'hsl(31, 70%, 50%)',
    },
  ];
  return (
    <ResponsivePie
      data={data}
      margin={{
        top: 40, right: 80, bottom: 80, left: 80,
      }}
      innerRadius={0.5}
      padAngle={0.7}
      cornerRadius={3}
      activeOuterRadiusOffset={8}
      borderWidth={1}
      borderColor={{ from: 'color', modifiers: [['darker', 0.2]] }}
      arcLinkLabelsSkipAngle={10}
      arcLinkLabelsTextColor={Colors.ligthWhite}
      arcLinkLabelsThickness={2}
      arcLinkLabelsColor={{ from: 'color' }}
      arcLabelsSkipAngle={10}
      arcLabelsTextColor={{ from: 'color', modifiers: [['darker', 2]] }}
      defs={[
        {
          id: 'dots',
          type: 'patternDots',
          background: 'inherit',
          color: 'rgba(255, 255, 255, 0.3)',
          size: 4,
          padding: 1,
          stagger: true,
        },
        {
          id: 'lines',
          type: 'patternLines',
          background: 'inherit',
          color: 'rgba(255, 255, 255, 0.3)',
          rotation: -45,
          lineWidth: 6,
          spacing: 10,
        },
      ]}
      fill={[
        {
          match: {
            id: 'Havale',
          },
          id: 'dots',
        },
        {
          match: {
            id: 'Kredi_Kartı',
          },
          id: 'lines',
        },
      ]}
      legends={[
        {
          anchor: 'bottom',
          direction: 'row',
          justify: false,
          translateX: 0,
          translateY: 56,
          itemsSpacing: 0,
          itemWidth: 100,
          itemHeight: 18,
          itemTextColor: Colors.ligthWhite,
          itemDirection: 'left-to-right',
          itemOpacity: 1,
          symbolSize: 18,
          symbolShape: 'circle',
          effects: [
            {
              on: 'hover',
              style: {
                itemTextColor: Colors.ligthWhite,
              },
            },
          ],
        },
      ]}
    />
  );
}

export default Pie;
