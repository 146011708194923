/* eslint-disable react/prop-types */
import {
  Backdrop, MenuItem, Modal, TextField,
} from '@material-ui/core';
import React, { useCallback, useEffect, useState } from 'react';
import { Close } from '@material-ui/icons';
import { useDispatch, useSelector } from 'react-redux';
import { Autocomplete } from '@material-ui/lab';
import { CustomButton, CustomFade, CustomIconButton } from '../../assets/styled';
import * as thunks from '../../store/thunks';

function BulkSalesModal({ open, onClose }) {
  const [warningOpen, setWarningOpen] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const [selectedPayment, setSelectedPayment] = useState(null);
  const [userCount, setUserCount] = useState(0);

  const dispatch = useDispatch();
  const createBulkSale = useCallback(data => dispatch(thunks.createBulkSale(data)), [dispatch]);

  const allUsers = useSelector(state1 => state1.allUsers);
  const userPaymentList = useSelector(state1 => state1.userPaymentList);

  useEffect(() => {
    if (allUsers.length === 0) {
      dispatch(thunks.getUser());
    }
  }, [dispatch]);

  const saveFunc = (checked) => {
    if (!checked) {
      setWarningOpen(true);
      return;
    }
    const data = {
      user_id: selectedUser.id,
      payment_id: selectedPayment,
      user_count: parseInt(userCount, 10),
    };
    createBulkSale(data);
    setWarningOpen(false);
    onClose();
  };

  useEffect(() => {
    if (selectedUser) {
      dispatch(thunks.getUserPaymentList(selectedUser.id));
    }
  }, [selectedUser, dispatch]);

  return (
    <>
      <Modal
        open={open}
        onClose={() => onClose()}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <CustomFade in={open}>
          <div>
            <div className="header">
              <h1>Toplu Ödeme Yaratma</h1>
              <CustomIconButton onClick={() => onClose()}><Close /></CustomIconButton>
            </div>
            <div className="content">
              <div className="form">
                <Autocomplete
                  id="combo-box-demo"
                  options={allUsers.filter(user => !user.is_removed)}
                  getOptionLabel={option => `${option.name} - ${option.mail}`}
                  onChange={(e, value) => setSelectedUser(value)}
                  value={selectedUser}
                  style={{ width: '100%', color: 'white' }}
                  renderInput={params => <TextField {...params} variant="standard" style={{ width: '100%', color: 'white' }} label="Ana Kullanıcı" />}
                />
                {
                  selectedUser && (
                    <TextField
                      select
                      fullWidth
                      variant="standard"
                      label="Ödeme Seçimi"
                      value={selectedPayment}
                      onChange={e => setSelectedPayment(e.target.value)}
                    >
                      {userPaymentList?.filter(pl => pl.is_bulk && pl.status === 'COMPLETED').map(option => (
                        <MenuItem key={option.id} value={option.id}>
                          {option?.id} - {option?.price}₺ / {option.Package?.name}
                        </MenuItem>
                      ))}
                    </TextField>
                  )
                }
                {
                  selectedPayment && (
                    <TextField
                      type="number"
                      fullWidth
                      variant="standard"
                      label="Kullanıcı Sayısı"
                      value={userCount}
                      onChange={e => setUserCount(e.target.value)}
                    />
                  )
                }
                <div className="buttonWrapper">
                  <CustomButton onClick={() => onClose()} lined>
                    Vazgeç
                  </CustomButton>
                  <CustomButton purple onClick={() => saveFunc()}>
                    Kaydet
                  </CustomButton>
                </div>
              </div>
            </div>
          </div>
        </CustomFade>
      </Modal>
      {
        warningOpen && (
          <Modal
            open={warningOpen}
            onClose={() => setWarningOpen(false)}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
              timeout: 500,
            }}
          >
            <CustomFade in={open}>
              <div>
                <div className="header">
                  <h1>DİKKAT!</h1>
                  <CustomIconButton onClick={() => setWarningOpen(false)}><Close /></CustomIconButton>
                </div>
                <div className="content">
                  <div className="form" style={{ color: 'white' }}>
                    <p>Toplu satış ekliyorsunuz.</p>
                    <p>Eğer onaylarsanız ödeme ve kullanıcı için {userCount} kadar referans kodu oluşturulacak.</p>
                    <p>Onaylıyor musunuz?</p>
                    <div className="buttonWrapper">
                      <CustomButton onClick={() => setWarningOpen(false)} lined>
                        Vazgeç
                      </CustomButton>
                      <CustomButton purple onClick={() => saveFunc(true)}>
                        Ödemeyi Onayla
                      </CustomButton>
                    </div>
                  </div>
                </div>
              </div>
            </CustomFade>
          </Modal>
        )
      }
    </>
  );
}

export default BulkSalesModal;
