import React, { useCallback, useEffect, useState } from 'react';
import { Grid, MenuItem, TextField } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import ReactQuill from 'react-quill';
import * as thunks from '../../store/thunks';
import { CustomButton, PageWrapper } from '../../assets/styled';

import 'react-quill/dist/quill.snow.css';
import { convertToLink } from '../../commons/utils';

function NewBlog() {
  const dispatch = useDispatch();
  const createBlogService = useCallback(data => dispatch(thunks.createBlog(data)), [dispatch]);

  const [title, setTitle] = useState('');
  const [keywords, setKeyWords] = useState('');
  const [content, setContent] = useState('');
  const [img, setImg] = useState('');
  const [selectedLanguage, setSelectedLanguage] = useState(null);

  const allLanguages = useSelector(state1 => state1.allLanguages);

  const saveBlog = () => {
    const data = {
      title,
      image_url: img,
      keywords: keywords.split(',') || [],
      content: content.toString('html'),
      link: convertToLink(title),
      relatedLanguage: selectedLanguage,
    };
    createBlogService(data);
  };

  useEffect(() => {
    dispatch(thunks.getLanguages());
  }, [dispatch]);

  return (
    <PageWrapper>
      <Grid container spacing="3">
        <Grid item lg={4} xs={12}>
          <TextField
            label="Blog Başlığı"
            fullWidth
            variant="standard"
            value={title}
            onChange={e => setTitle(e.target.value)}
          />
        </Grid>
        <Grid item lg={4} xs={12}>
          <TextField
            label="Görsel"
            fullWidth
            variant="standard"
            placeholder="Görsel Yolu"
            value={img}
            onChange={e => setImg(e.target.value)}
          />
        </Grid>
        <Grid item lg={4} xs={12}>
          <TextField
            label="Key Words"
            fullWidth
            variant="standard"
            placeholder="Virgül ile ayırınız. Örnek: İngilizce, Dil öğrenme, hafıza teknikleri gibi"
            value={keywords}
            onChange={e => setKeyWords(e.target.value)}
          />
        </Grid>
        <Grid item lg={4} xs={12} style={{ display: 'flex', alignItems: 'center', gap: 8 }}>
          <TextField
            select
            fullWidth
            del
            variant="standard"
            label="İlgili Diller"
            value={selectedLanguage}
            onChange={e => setSelectedLanguage(e.target.value)}
          >
            {allLanguages?.filter(pl => !pl.is_removed).map(option => (
              <MenuItem key={option.id} value={option.id}>
                {option?.name}
              </MenuItem>
            ))}
          </TextField>
          <CustomButton onClick={() => setSelectedLanguage(null)}>Seçilen Dili Temizle</CustomButton>
        </Grid>
        <Grid item lg={12} xs={12}>
          <p style={{ color: 'white' }}>Link: {convertToLink(title)}</p>
        </Grid>
        <Grid item lg={12} xs={12} style={{ background: 'white' }}>
          <ReactQuill
            theme="snow"
            value={content}
            onChange={setContent}
            formats={[
              'header',
              'font',
              'size',
              'bold',
              'italic',
              'underline',
              'strike',
              'blockquote',
              'list',
              'bullet',
              'indent',
              'link',
              'image',
              'color',
            ]}
          />
        </Grid>
        <Grid item lg={12} xs={12}>
          <CustomButton component="label" onClick={() => saveBlog()}>Kaydet</CustomButton>
        </Grid>
      </Grid>
    </PageWrapper>
  );
}

export default NewBlog;
