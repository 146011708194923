import React, { useState, useCallback, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  InputAdornment, TextField,
} from '@material-ui/core';
import { Redirect } from 'react-router-dom';
import {
  CustomButton, LoginWrapper,
} from '../assets/styled';
import {
  Locker, Logo, Person,
} from '../assets/icons';
import LoginBg from '../assets/img/bigBlue.png';
import * as thunks from '../store/thunks';
import { Colors } from '../assets/statics';

function Login() {
  // const [email, setEmail] = useState('burak.sevinc@me.com');
  // const [password, setPassword] = useState('123456');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  // Redux Store Verisi çağırma
  const activeUser = useSelector(state => state.activeUser);

  // Action Listesi
  const dispatch = useDispatch();
  const doLogin = useCallback(() => dispatch(thunks.doLogin(email, password)), [dispatch, email, password]);


  // Fonksiyon Direkt Çalışacaksa
  // useEffect(() => {
  //   dispatch(thunks.doLogin(email, password));
  // }, [dispatch, email, password]);

  // useEffect(() => {
  //   if (localStorage.getItem('token')) {
  //     window.location.replace('/');
  //   }
  // });

  function login(e) {
    if (email?.length > 0 && password?.length > 0 && e.key === 'Enter') {
      doLogin();
    }
  }

  return (
    <LoginWrapper
      bg={LoginBg}
      // style={{
      //   backgroundImage: `url(${LoginBg})`,
      // }}
      onKeyDown={e => login(e)}
    >
      {
        activeUser?.id && (
          <Redirect to="/" />
        )
      }
      <div className="right">
        <div className="logo">
          <Logo />
        </div>
        <div className="form">
          <TextField
            placeholder="Kullanıcı Adı"
            fullWidth
            autoFocus
            type="email"
            variant="outlined"
            value={email}
            onChange={e => setEmail(e.target.value)}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Person color={Colors.darkLightBg} />
                </InputAdornment>
              ),
            }}
          />
          <TextField
            placeholder="Şifre"
            fullWidth
            type="password"
            variant="outlined"
            value={password}
            onChange={e => setPassword(e.target.value)}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Locker color={Colors.darkLightBg} />
                </InputAdornment>
              ),
            }}
          />
        </div>
        <div className="bottom">
          {/* <div>
            <Simplelink>
              Şifremi Unuttum
            </Simplelink>
          </div> */}
          <CustomButton
            onClick={doLogin}
            purple
            fullWidth
            large
          >
            Login
          </CustomButton>
        </div>
      </div>
    </LoginWrapper>
  );
}

export default Login;
