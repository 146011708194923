import styled from 'styled-components';
// import MUIDataTable from 'mui-datatables';
import { Menu, MenuItem } from '@material-ui/core';
import { Colors } from './statics';
import { hexToRgbA } from '../commons/utils';

const CustomTable = styled.table`
  width: 100%;
  border-collapse:separate;
  border-collapse: collapse;
  border-spacing: 0 10px;
  white-space: nowrap;
  background-color: ${Colors.darkSemiBg};
  /* position: relative; // temp */
  tr:nth-child(odd),tr:not(.pagination)  {
    /* cursor: pointer; */
    :hover {
      /* background: ${Colors.grayLight}; */
      /* tr, td, th {
        border-bottom: 1px solid ${Colors.grey};
        border-top: 1px solid ${Colors.grey};
        :first-child {
          border-color: ${Colors.grey};
        }
        :last-child {
          border-color: ${Colors.grey};
        }
      } */
    }
  }
  tr:nth-child(odd) {
    background: ${Colors.darkLightBg};
  }
  tr, td, th {
    border-spacing: 0px;
    /* color: ${props => props.theme.text1}; */
    /* background-color: ${Colors.white}; */
    font-size: 12px;
    transition: all 0.5s ease;
    cursor: default;
    /* border-bottom: 1px solid ${Colors.lightGrey400};
    border-top: 1px solid ${Colors.lightGrey400};
    :first-child {
      border-width: 1px 0px 1px 1px;
      border-style: solid;
      border-color: ${Colors.lightGrey400};
      border-top-left-radius: 15px;
      border-bottom-left-radius: 15px;
    }
    :last-child {
      border-width: 1px 1px 1px 0px;
      border-style: solid;
      border-color: ${Colors.lightGrey400};
      border-top-right-radius: 15px;
      border-bottom-right-radius: 15px;
    } */
    a {
      /* color: ${Colors.blueBlack}; */
    }
    /* border: none!important; */
  }
  tr {
    border-bottom: 1px solid ${Colors.grey};
    :last-of-type {
      border: none;
    }
    &:hover {
      /* box-shadow: 0px -5px 24px rgba(0, 0, 0, 0.1); */
      background-color: ${hexToRgbA(Colors.darkLightBg, 0.9)};
    }
  }
  th {
    user-select: none;
    background-color: ${Colors.darkSemiBg};
    /* text-align: center; */
    color: ${Colors.darkWhite};
    font-weight: 600;
    position: relative;
    font-size: 16px;
    .sortable {
      position: absolute;
      right: 0;
      top: 50%;
      width: 25px;
      height: 25px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      transform: translate(0, -50%);
      overflow: hidden;
      svg {
        color: ${Colors.darkWhite};
      }
      svg:first-of-type {
        /* top: -7px; */
        visibility: hidden;
      }
      svg:last-of-type {
        margin-top: -15px;
        visibility: hidden;
      }
    }
    :hover {
      svg:first-of-type {
        visibility: visible;
      }
      svg:last-of-type {
        visibility: visible;
      }
    }
  }
  input {
    font-size: 12px;
  }
  .MuiInput-underline:after, .MuiInput-underline:before {
    display: flex!important;
  }
  /* tbody tr:nth-child(odd) {
    background-color: #FFFFFF;
  }
  tbody tr:nth-child(even) {
    background-color: #F7FAFC;
  }
  tbody td:not(:first-child) * {
    font-size: 13px;
  } */
  .MuiCheckbox-root {
    color: ${Colors.purple}!important;
  }
  h6 {
    display: none!important;
  }
  
  .MuiFormHelperText-root {
    display: none!important;
  }
`;

export const CustomTableTh = styled.th`
  text-align: ${props => props.textAlign};
  display: ${props => props.visible && 'none'};
  font-weight: 400;
  /* padding: 15px; */
  padding: 0;
  height: 35px;
  /* background-color: ${Colors.lightWhite}!important; */
  &:first-of-type {
    padding-left: 20px;
  }
  .headerIconsTable {
    position: absolute;
    /* right: -10px; */
    right: 0px;
    /* transform: translate(0, -50%); */
    height: inherit;
    > * {
      padding: 0;
      height: inherit;
      background: ${Colors.purple};
      /* border-radius: 0 10px 10px 0; */
      border-radius: 0 6px;
      width: 30px;
      svg {
        color: ${Colors.ligthWhite};
      }
    }
  }
`;

export const CustomTableTr = styled.tr`
  background: ${props => props.editing && props.theme.tableHeader};
  padding-right: ${props => props.actionRow && '40px'};
  :hover {
    td {
      background-color: ${Colors.darkLightBg};
      background-color: ${props => props.selected && hexToRgbA(Colors.redLight, 0.2)};
    }
  }
  td {
    ${props => props.isSubTotal && 'background-color: #FBDE2D!important;'}
    /* padding: 10px; */
    padding: 5px;
    /* max-width: 100px; */
    overflow: hidden;
    text-overflow: ellipsis;
    background-color: ${props => props.selected && Colors.darkLightBg};
    font-size: 14px;
    color: ${Colors.ligthWhite}
    ${props => props.modalType && (`
      padding: 5px 0;
      background-color: ${Colors.grayExtraLight};
    `)}
  }
`;

export const CustomTableTd = styled.td`
  text-align: ${props => props.textAlign};
  display: ${props => props.visible && 'none'};
  .MuiOutlinedInput-input {
    padding: 10px;
    border: none;
  }
  .MuiFormControl-root {
    padding: 0 10px;
    min-width: 130px;
  }
  .MuiFormControl-marginNormal {
    margin-top: 0px;
    margin-bottom: 0px;
  }
  input {
    text-align: center;
  }
  &:first-of-type {
    padding-left: 20px;
  }
`;

export const SettingMenu = styled(Menu)`

`;

export const SettingMenuItem = styled(MenuItem)`
  display: flex;
  justify-content: space-between;
  font-size: 13px;
  background: ${Colors.darkLightBg};
  color: ${Colors.darkWhite};
  opacity: ${props => (props.active ? 1 : 0.8)};
  height: 35px;
  border-top: 1px solid ${Colors.lightGrey};
  &:first-of-type {
    border: none;
  }
  :active {
    background: ${Colors.redLight};
  }
  svg path {
    fill: ${Colors.blue};
    fill: ${props => (props.active ? Colors.purple : Colors.grey)};
  }
  :hover {
    background: ${Colors.darkSemiBg};
  }
`;

export const TablePagination = styled.div`
  display: flex;
  justify-content: ${props => (props.position ? props.position : 'center')};
  align-items: center;
  @media (max-width: 399px) {
    flex-wrap: wrap;
  }
`;

export const PagiItem = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid ${Colors.grey};
  color: ${Colors.grey};
  background-color: ${Colors.darkLightBg};
  border-radius: 5px;
  padding: 8px 10px;
  /* margin-right: 5px; */
  margin-top: 5px;
  & + & {
    margin-left: 5px;
  }
  cursor: pointer;
  font-weight: 600;
  > svg {
    path:nth-child(2) {
      fill: ${props => props.theme.textBlue};
    }
  }
  ${props => props.active && `
    color: ${Colors.ligthWhite};
    background-color: ${Colors.darkSemiBg};
  `}
  ${props => props.disabled && `
    cursor: default;
    > svg {
      path:nth-child(2) {
        opacity: 0.5;
      }
    }
  `}
`;

export const CustomTableHeader = styled.div`
  /* background-color: ${props => (props.search ? props.theme.tableHeader : props.theme.inputBackg)}; */
  /* border: 1px solid ${props => props.theme.inputBorder}; */
  border-radius:15px;
  position: relative;
  padding: 5px 10px;
  margin-bottom: 5px;
  /* overflow: hidden; */
  height: 0px;
  padding: 0;
  margin: 0;
  /* .headerIcons {
    > .MuiIconButton-root {
      padding: 5px!important;
    }
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 20px;
    background-color: ${Colors.blueBlack};
    right: -30px;
    top: 10px;
    height: 35px;
    border-radius: 0 10px 10px 0;
    height: 35px;
    width: 40px;
    width: 30px;
    z-index: 1;
    svg {
      color: ${Colors.white};
    }
  }
  .MuiInputBase-root {
    color: ${props => props.theme.text1};
  }
  @media (max-width: 768px) {
    .headerIcons {
      right: -10px;
    }
  } */
`;

export const CustomTableFilter = styled.div`
  /* margin-bottom: 20px; */
  .boxWrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    overflow: auto;
    white-space: nowrap;
    /* padding: 10px 0px; */
    padding: 0px;
    .resetAll {
      transition: all 0.5s ease;
      font-weight: 600;
      color: ${Colors.blueBlack};
      margin-left: 15px;
    } 
    .filterTag {
      transition: all 0.5s ease;
      display: flex;
      flex-direction: row;
      justify-content: space-betwen;
      padding: 10px;
      align-items: center;
      background-color: ${props => props.theme.purpleBackg};
      border-radius: 5px;
      :not(:first-child) {
        margin-left: 15px;
      }
      .icon {
        box-shadow: 0px 3px 5px rgba(53, 63, 102, 0.25);
        display: flex;
        justify-content: center;
        align-items: center;
        width: 25px;
        height: 25px;
        border-radius: 50%;
        background-color: ${Colors.blue};
        svg {
          fill: ${props => props.theme.lightPurple200};
        }
      }
      .info {
        margin-left: 10px;
        display: flex;
        flex-direction: column;
        p {
          font-size: 12px;
          font-weight: 600;
          :first-child {
            color: ${props => props.theme.blueText};
          }
          :last-child {
            margin-top: 5px;
            color: ${props => props.theme.purpleText};
          }
        }
      }
    }
  }
  .descWrapper {
    /* margin-top: 10px; */
    p {
      font-size: 12px;
      font-weight: 600;
      color: ${props => props.theme.text2};
      span {
        color: ${props => props.theme.blueText};
      }
    }
  }
  button.MuiButtonBase-root.MuiIconButton-root {
    padding-right: 12px;
  }
  @media (max-width: 768px) {
    .boxWrapper .filterTag {
      &:not(:first-child) {
        margin-left: 0;
      }
      .info {
        margin-left: 5px;
        p:last-child {
          margin-top: 0;
        }
      }
    }
  }
`;

export const PaginationWrapper = styled.div`
  /* border: none !important; */
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* height: 50px; */
  padding: 5px 0; 
  background-color: ${Colors.darkLightBg};
  border-radius: 0 0 6px 6px;
  font-size: 12px;
  flex-wrap: wrap;
  border: 1px solid ${Colors.grey};
  border-top: none;
  .perPage {
    display: flex;
    align-items: center;
    padding: 5px 10px;
    padding-left: 10px;
    p {
      padding-left: 10px;
      color: ${Colors.grey};
    }
  }
  ${TablePagination} {
    padding: 5px 10px;
    padding-right: 10px;
    margin-left: auto;
  }
  .MuiSelect-select.MuiSelect-select {
    padding-right: 24px;
    color: ${Colors.grey};
  }
  .MuiSelect-icon {
    color: ${Colors.grey};
  }
  .MuiInput-underline:before {
    border-bottom: 1px solid ${Colors.grey};
  }
  @media (max-width: 768px) {
    justify-content: center;
    ${TablePagination} {
      margin-left: 0;
    }
    .MuiFormControl-root {
      min-width: 52px;
      color: red;
    }
  }
`;

export const TableMainwrapper = styled.div`
  overflow: overlay;
  width: 100%;
  /* padding: 10px 0; */
  border: 1px solid ${Colors.grey};
  border-radius: 6px 6px 0 0;
  span.MuiCheckbox-root { // table checkbox
    padding: 0;
  }
  @media (max-width: 768px) {
    padding-bottom: 4px;
  }
`;

export default CustomTable;
