/* eslint-disable object-curly-newline */
import React, { useCallback, useEffect, useState } from 'react';
import { Grid, MenuItem, TextField } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { Edit, MoreVert } from '@material-ui/icons';
import {
  CustomButton,
  CustomIconButton, MobileMoreMenu, MobileMoreMenuItem, PaymentPage,
  TabbleStatusBox,
} from '../assets/styled';
import Table from '../components/Table';
import * as thunks from '../store/thunks';
import ChangePaymentStatus from '../components/modals/ChangePaymentStatus';
import { payment_status, payment_types } from '../commons/constants';
import PaymentNotification from '../components/modals/PaymentNotification';

function Payments() {
  const dispatch = useDispatch();
  const getPaymentList = useCallback(data => dispatch(thunks.getPaymentList(data)), [dispatch]);
  const data = useSelector(state => state.paymentList);
  const allLanguages = useSelector(state1 => state1.allLanguages);
  const [state, setState] = React.useState({
    // searchFilter: '',
  });
  const [openModal, setOpenModal] = useState(false);

  const [paymentType, setPaymentType] = useState('DEBIT');
  const [status, setStatus] = useState('PENDING');
  const [selectedPayment, setSelectedPayment] = useState(null);

  useEffect(() => {
    getPaymentList({ payment_type: paymentType, status });
    dispatch(thunks.getLanguages());
  }, [getPaymentList, paymentType, status]);

  const columns = [
    {
      name: 'id',
      text: 'Id',
      filterable: true,
      editable: false,
      align: 'center',
      sortable: true,
      type: 'text',
    },
    {
      name: 'status',
      text: 'Durumu',
      filterable: true,
      editable: false,
      align: 'center',
      sortable: true,
      type: 'text',
    },
    {
      name: 'payment_type',
      text: 'Ödeme Tipi',
      filterable: true,
      editable: false,
      align: 'center',
      sortable: true,
      type: 'text',
      customBody: (value, columnName, index, row) => (
        <p>{`${value} ${row.is_bulk ? '- Toplu Satış' : ''}`}</p>
      ),
    },
    {
      name: 'user_id',
      text: 'Kullanıcı',
      filterable: true,
      editable: false,
      align: 'center',
      sortable: true,
      type: 'text',
      customBody: (value, columnName, index, row) => (
        <p>{`${row.User?.id} - ${row.User?.mail}`}</p>
      ),
    },
    {
      name: 'package_id',
      text: 'Paket Adı',
      filterable: true,
      editable: false,
      align: 'center',
      sortable: true,
      type: 'text',
      customBody: (value, columnName, index, row) => (
        <p>{row.Package?.name}</p>
      ),
    },
    {
      name: 'languages',
      text: 'Diller',
      filterable: true,
      editable: false,
      align: 'center',
      sortable: true,
      type: 'text',
      customBody: value => (
        <div style={{ display: 'flex', gap: 3 }}>
          {
            value?.map(l => (
              <TabbleStatusBox status={3}>
                {allLanguages.find(lang => lang.id === l)?.name}
              </TabbleStatusBox>
            ))
          }
        </div>
      ),
    },
    {
      name: 'conversationId',
      text: 'Ref / Ödeme ID',
      filterable: true,
      editable: false,
      align: 'center',
      sortable: true,
      type: 'text',
    },
    {
      name: 'price',
      text: 'Tutar',
      filterable: true,
      editable: false,
      align: 'center',
      sortable: true,
      type: 'text',
      customBody: value => (
        <span>{value && new Intl.NumberFormat('tr-TR', { style: 'currency', currency: 'TRY' }).format(value)}</span>
      ),
    },
    {
      name: 'Action',
      text: 'Action',
      filterable: false,
      editable: false,
      align: 'left',
      sortable: false,
      type: 'action',
      disableSetting: false,
      customBody: (value, columnName, index, row) => (
        <div id="tableButton">
          <CustomIconButton onClick={(e) => { setState({ [`anchorMobileTable${row.id}`]: e.currentTarget }); }}>
            {/* <MobileTripleIcon /> */}
            <MoreVert />
          </CustomIconButton>
          {
            state[`anchorMobileTable${row.id}`] && (
              <MobileMoreMenu
                id="mobileMoreMenu"
                anchorEl={state[`anchorMobileTable${row.id}`]}
                keepMounted
                open={Boolean(state[`anchorMobileTable${row.id}`])}
                onClose={() => { setState({ [`anchorMobileTable${row.id}`]: null }); }}
                // placement="left"
                anchorOrigin={{ horizontal: 'left', vertical: 'center' }}
                transformOrigin={{ horizontal: 'right', vertical: 'center' }}
                disablePortal={false}
                modifiers={{
                  flip: {
                    enabled: false,
                  },
                  preventOverflow: {
                    enabled: true,
                    boundariesElement: 'scrollParent',
                  },
                  arrow: {
                    enabled: true,
                    element: state[`anchorMobileTable${row.id}`],
                  },
                }}
              >
                <MobileMoreMenuItem
                  // active={!state[`columnVisibility${tabValue}`]}
                  onClick={() => { setState({ [`anchorMobileTable${row.id}`]: null }); setSelectedPayment(row.id); }}
                >
                  <Edit />
                  Ödeme Durumunu Değiştir.
                </MobileMoreMenuItem>
              </MobileMoreMenu>
            )
          }
        </div>
      ),
    },
  ];

  return (
    <PaymentPage>
      <Grid container>
        <div style={{ display: 'flex', width: '100%', justifyContent: 'space-between', alignItems: 'center' }}>
          <h3>Ödemeler</h3>
          <CustomButton purple onClick={() => setOpenModal(true)}>Ödeme Bildirimi Ekle</CustomButton>
        </div>
        <Grid item lg={12} xs={12}>
          <div className="inputArea">
            <TextField
              select
              label="Ödeme Tipi"
              fullWidth
              variant="standard"
              value={paymentType}
              onChange={(e) => { setPaymentType(e.target.value); }}
            >
              {payment_types?.map(option => (
                <MenuItem key={option.id} value={option.id}>
                  {option.name}
                </MenuItem>
              ))}
            </TextField>
            <TextField
              select
              label="Ödeme Durumu"
              fullWidth
              variant="standard"
              value={status}
              onChange={(e) => { setStatus(e.target.value); }}
            >
              {payment_status?.map(option => (
                <MenuItem key={option.id} value={option.id}>
                  {option.name}
                </MenuItem>
              ))}
            </TextField>
            <p>Toplam: <strong>{data?.length}</strong> kayıt.</p>
          </div>
        </Grid>
        <Grid item lg={12} xs={12}>
          <Table
            data={data || []}
            columns={columns || []}
            id="reportsTable"
            options={{
              selectableRows: 'none',
              header: {
                visible: true,
                columnSetting: false,
              },
              filter: {
                show: true,
                filters: [],
                // onChange: (state, value, text, type) => console.log(state, value, text, type),
                // drawerChange: () => setState(prevstate => ({ filterDrawer: !prevstate.filterDrawer })),
              },
              pagination: {
                active: false,
                perPage: 20,
              },
            }}
            // rowClick={row => console.log(row)}
            // rowClick={() => window.location.replace('/SuggestionDetail')}
          />
        </Grid>
      </Grid>
      {
        selectedPayment && (
          <ChangePaymentStatus
            open={selectedPayment}
            onClose={() => setSelectedPayment(null)}
            listParams={{ payment_type: paymentType, status }}
            data={data.find(select => select.id === selectedPayment)}
          />

        )
      }
      {
        openModal && (
          <PaymentNotification
            open={openModal}
            onClose={() => setOpenModal(false)}
          />
        )
      }
    </PaymentPage>
  );
}

export default Payments;
