import React, { useCallback, useEffect } from 'react';
import { HashRouter } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Routes from './commons/routes';
import { GlobalStyle, MainWrapper } from './assets/styled';
import Toast from './commons/Toast';
import Siedebar from './components/Siedebar';
import * as actions from './store/actions';
import Header from './components/Header';
import * as thunks from './store/thunks';

function App() {
  const dispatch = useDispatch();
  const getInit = useCallback(() => dispatch(thunks.getInit()));
  const getStatics = useCallback(() => dispatch(thunks.getStatics()));
  const toast = useSelector(state1 => state1.toast);
  const activeUser = useSelector(state1 => state1.activeUser);
  const sidebarStatu = useSelector(state1 => state1.appConfig?.sidebar);
  useEffect(() => {
    getInit();
    getStatics();
  }, [dispatch]);

  return (
    <HashRouter>
      <Toast
        isOpen={toast.open}
        message={toast.message}
        handleClose={() => dispatch(actions.setToast(false))}
        variant={toast.variant}
      />
      <div className="mainContent">
        <div style={{ display: 'flex' }}>
          {(!!localStorage.getItem('token') || activeUser?.id) && <Siedebar />}
          <MainWrapper sideOpen={sidebarStatu} singed={(!!localStorage.getItem('token') || activeUser?.id)}>
            {(!!localStorage.getItem('token') || activeUser?.id) && <Header />}
            <Routes />
          </MainWrapper>
        </div>
      </div>
      <GlobalStyle />
    </HashRouter>
  );
}

export default App;
