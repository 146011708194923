export const Colors = {
  // lightGreen: '#67C9BB',
  darkGreen: '#19AD95',
  white: '#ffffff',
  // yellow: '#FFCE00',
  red: '#F53C56',
  green: '#ADD328',
  // blue: '#00539F', // eski
  blue: '#0353A0',
  blueBlack2: '#002E58',
  blueLight: '#88CCFB',
  // purple: '#8B52CA',
  lightPurple: '#7764E4',
  islamicGreen: '#00953B',
  // orange: '#F68121',
  // black: '#474A5C',
  navy: '#01203D',
  // error: '#f44336',
  // darkGrey: '#737B8B',
  // lightGrey: '#ECEFF2',
  // grey: '#96A0AF',
  // lightBlack: '#474A5C',
  silver: '#919DC0',
  lightOrange: '#FFBB54',
  lightWhite: '#FBFBFE',
  burgundy: '#B00F0F',
  blueLight2: '#1779D3',
  semiLightBlue: '#20A4FF',
  pink: '#FF9090',
  mint: '#ADD328',
  grey700: '#374151',
  lightBlack: '#1E201F',
  darkBlack: '#252F3F',
  gold: '#DDAF11',
  success: '#3CC13B',
  error: '#F03738',
  warning: '#F3BB1C',
  lightBlue: '#3E66FB',
  dark: '#0D1C2E',
  lightGrey: '#F4F4F4',
  semiLightGrey: '#4254661A',
  grey: '#838F9C',
  yellow: '#FDC43E',
  brown: '#7E5F16',
  darkBrown: '#7C4F33',
  darkGrey: '#425466',
  greyBorder: '#E1E8F1',
  dashboardGrey: '#F8F9FA',

  purple: '#6236FF',
  orange: '#FA6400',
  darkBg: '#202225',
  darkSemiBg: '#2f3136',
  darkLightBg: '#36393f',
  darkWhite: '#a7a7a7',
  ligthWhite: '#F4F4F4',

  // darkGrey: '#FF78746D',
  body: '#FFE5E5E5',
  primary: '#FF2CB9B0',
  primarySoft: '#FFA0D5D2',
  primarySoftest: '#FFC9E9E7',
  secondary: '#FF0C0D34',
  // yellow: '#FFFFC641',
};

export const userTypeData = [
  { value: 1, label: 'Admin' },
  { value: 2, label: 'Kullanıcı' },
];
