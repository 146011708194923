import React, { useCallback, useEffect, useState } from 'react';
import {
  Dialog,
  FormControlLabel,
  Grid, InputAdornment, MenuItem, Switch, TextField,
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import {
  Cancel, CheckCircle, Close, MoreVert, Search,
} from '@material-ui/icons';
import {
  CustomButton,
  CustomDialog,
  CustomIconButton,
  MobileMoreMenu,
  MobileMoreMenuItem,
  PageWrapper,
  TabbleStatusBox,
} from '../assets/styled';
import Table from '../components/Table';
import * as thunks from '../store/thunks';
import { Edit, Plus } from '../assets/icons';
import NewWord from '../components/modals/NewWord';
import Player from '../components/Player';

function Words() {
  const [state, setState] = React.useState({
    // searchFilter: '',
  });
  const [activeLangId, setActiveLangId] = useState(1);
  const [activeCatId, setActiveCatId] = useState(null);
  const [activeCatSubId, setActiveCatSubId] = useState(null);
  const [activeCatIsSub, setActiveCatIsSub] = useState(false);

  const [searchFilter, setSearchFilter] = useState('');
  const [filterType, setFilterType] = useState('all');
  const [activeFilter, setActiveFilter] = useState(1);

  const [openWordModal, setOpenWordModal] = useState(false);
  const [selectedWordId, setSelectedWordId] = useState(null);
  const [edit, setEdit] = useState(false);

  const [imageDialogOpen, setImageDialogOpen] = useState(false);
  const [imageDialogSource, setImageDialogSource] = useState(false);

  // Redux Store Verisi çağırma
  const category = useSelector(state1 => state1.category);
  const categorySub = useSelector(state1 => state1.categorySub);
  const allLanguages = useSelector(state1 => state1.allLanguages);
  // const allWord = useSelector(state1 => state1.allWord);
  const word = useSelector(state1 => state1.word);

  // Action Listesi
  const dispatch = useDispatch();
  const deleteWord = useCallback(() => dispatch(thunks.deleteWord(selectedWordId, activeCatIsSub ? activeCatSubId : activeCatId)), [activeCatId, activeCatIsSub, activeCatSubId, dispatch, selectedWordId]);

  // Fonksiyon Direkt Çalışacaksa
  useEffect(() => {
    if (activeLangId) {
      dispatch(thunks.getCategory(activeLangId));
    }
  }, [dispatch, activeLangId]);

  useEffect(() => {
    dispatch(thunks.getLanguages());
  }, [dispatch]);

  useEffect(() => {
    if (category?.length > 0) {
      setActiveCatId(category[0]?.id);
    }
  }, [category, activeLangId]);


  useEffect(() => {
    if (activeCatId) {
      dispatch(thunks.getCategorySub(activeCatId));
      dispatch(thunks.getWord(activeCatId));
    }
  }, [dispatch, activeCatId]);

  useEffect(() => {
    if (categorySub?.length > 0) {
      setActiveCatSubId(categorySub[0]?.id);
    }
  }, [categorySub, activeLangId]);

  function toggleSwtich() {
    if (!activeCatIsSub) {
      setActiveCatSubId(categorySub[0]?.id);
      dispatch(thunks.getWord(categorySub[0]?.id));
    } else {
      // setActiveCatSubId(null);
      dispatch(thunks.getWord(activeCatId));
    }
    setActiveCatIsSub(!activeCatIsSub);
  }

  // useEffect(() => {
  //   if (activeCatIsSub && activeCatSubId) {
  //     dispatch(thunks.getWord(activeCatSubId));
  //   } else if (activeCatId) {
  //     dispatch(thunks.getWord(activeCatId));
  //   }
  // }, [dispatch, activeCatId, activeCatSubId, activeCatIsSub]);

  const columns = [
    {
      name: 'is_removed',
      text: 'Durumu',
      filterable: true,
      editable: false,
      align: 'left',
      sortable: true,
      type: 'text',
      customBody: value => (
        <TabbleStatusBox status={value ? 2 : 3}>
          {value ? 'Pasif' : 'Aktif'}
        </TabbleStatusBox>
      ),
    },
    {
      name: 'id',
      text: 'Id',
      filterable: true,
      editable: false,
      align: 'center',
      sortable: true,
      type: 'text',
    },
    {
      name: 'lang_id',
      text: 'Dil Id',
      filterable: true,
      editable: false,
      align: 'center',
      sortable: true,
      type: 'text',
    },
    {
      name: 'name',
      text: 'İsim',
      filterable: false,
      editable: false,
      align: 'left',
      sortable: true,
      type: 'text',
    },
    {
      name: 'meaning',
      text: 'Anlamı',
      filterable: false,
      editable: false,
      align: 'left',
      sortable: true,
      type: 'text',
    },
    {
      name: 'reading',
      text: 'Okunuşu',
      filterable: false,
      editable: false,
      align: 'left',
      sortable: true,
      type: 'text',
    },
    {
      name: 'similar',
      text: 'Benzer',
      filterable: false,
      editable: false,
      align: 'left',
      sortable: true,
      type: 'text',
    },
    {
      name: 'image_url',
      text: 'Resim Url',
      filterable: false,
      editable: false,
      align: 'left',
      sortable: true,
      type: 'text',
      customBody: value => (
        <CustomButton purple onClick={() => { setImageDialogSource(value); setImageDialogOpen(true); }}>Gör</CustomButton>
      ),
    },
    {
      name: 'voice_reading_url',
      text: 'Okunuş Ses Url',
      filterable: false,
      editable: false,
      align: 'left',
      sortable: true,
      type: 'text',
      customBody: value => (
        <Player url={value} />
      ),
    },
    {
      name: 'voice_memory_url',
      text: 'Hafıza Ses Url',
      filterable: false,
      editable: false,
      align: 'left',
      sortable: true,
      type: 'text',
      customBody: value => (
        <Player url={value} />
      ),
    },
    {
      name: 'text',
      text: 'Metin',
      filterable: false,
      editable: false,
      align: 'left',
      sortable: true,
      type: 'text',
      customBody: value => (
        // <div>{value ? 'Düzenleden gör' : 'yok'}</div>
        (value)
      ),
    },
    // {
    //   name: 'updatedAt',
    //   text: 'Güncelleme Tarihi',
    //   filterable: false,
    //   editable: false,
    //   align: 'left',
    //   sortable: true,
    //   type: 'text',
    //   customBody: value => (
    //     <div>
    //       {dateTool(value)}
    //     </div>
    //   ),
    // },
    {
      name: 'Action',
      text: 'Action',
      filterable: false,
      editable: false,
      align: 'left',
      sortable: false,
      type: 'action',
      disableSetting: false,
      customBody: (value, columnName, index, row) => (
        <div id="tableButton">
          <CustomIconButton onClick={(e) => { setState({ [`anchorMobileTable${row.id}`]: e.currentTarget }); setSelectedWordId(row.id); }}>
            {/* <MobileTripleIcon /> */}
            <MoreVert />
          </CustomIconButton>
          {
            state[`anchorMobileTable${row.id}`] && (
              <MobileMoreMenu
                id="mobileMoreMenu"
                anchorEl={state[`anchorMobileTable${row.id}`]}
                keepMounted
                open={Boolean(state[`anchorMobileTable${row.id}`])}
                onClose={() => { setSelectedWordId(null); setEdit(false); setState({ [`anchorMobileTable${row.id}`]: null }); }}
                // placement="left"
                anchorOrigin={{ horizontal: 'left', vertical: 'center' }}
                transformOrigin={{ horizontal: 'right', vertical: 'center' }}
                disablePortal={false}
                modifiers={{
                  flip: {
                    enabled: false,
                  },
                  preventOverflow: {
                    enabled: true,
                    boundariesElement: 'scrollParent',
                  },
                  arrow: {
                    enabled: true,
                    element: state[`anchorMobileTable${row.id}`],
                  },
                }}
              >
                <MobileMoreMenuItem
                  // active={!state[`columnVisibility${tabValue}`]}
                  onClick={() => { setState({ [`anchorMobileTable${row.id}`]: null }); setSelectedWordId(row.id); setEdit(true); setOpenWordModal(true); }}
                >
                  <Edit />
                  Kelimeyi Düzenle
                </MobileMoreMenuItem>
                <MobileMoreMenuItem
                  // active={!state[`columnVisibility${tabValue}`]}
                  onClick={() => { setState({ [`anchorMobileTable${row.id}`]: null }); setSelectedWordId(row.id); window.location.replace(`#/CreateWords/${row.id}`); }}
                >
                  <Edit />
                  Kelimeyi Düzenlemeddin
                </MobileMoreMenuItem>
                <MobileMoreMenuItem
                  // active={!state[`columnVisibility${tabValue}`]}
                  onClick={() => { deleteWord(); setState({ infoDeleteModal: true, [`anchorMobileTable${row.id}`]: null }); }}
                >
                  {row.is_removed ? <CheckCircle /> : <Cancel />}
                  {row.is_removed ? 'Aktif Yap' : 'Pasif Yap'}
                </MobileMoreMenuItem>
              </MobileMoreMenu>
            )
          }
        </div>
      ),
    },
  ];

  const filteredActive = filterType !== 'all' ? word?.filter(item => item.is_removed?.toString() === filterType) : word;
  const lowercasedFilter = searchFilter?.length > 0 && searchFilter?.toLocaleUpperCase();
  const filteredData = searchFilter.length > 0 ? (filteredActive && filteredActive?.filter(item => Object?.keys(item)?.some(key => typeof (item[(key)]) === 'string'
  && item[(key)]?.toLocaleUpperCase()?.includes(lowercasedFilter)))) : filteredActive;

  return (
    <PageWrapper>
      <Grid container spacing="3">
        <Grid item lg={12} xs={12}>
          <div className="pageHeader">
            <TextField
              label="Ara"
              placeholder="Tablo'da ara"
              variant="standard"
              InputProps={{
                startAdornment: <InputAdornment position="start"><Search color="primary" /></InputAdornment>,
                endAdornment: searchFilter?.length > 0 && <InputAdornment position="end"><CustomIconButton noPad onClick={() => setSearchFilter('')}><Close color="primary" /></CustomIconButton></InputAdornment>,
              }}
              value={searchFilter}
              onChange={e => setSearchFilter(e.target.value)}
            />
            <TextField
              select
              // fullWidth
              variant="standard"
              label="Dil"
              value={activeLangId}
              onChange={(e) => { setActiveLangId(e.target.value); setActiveCatIsSub(false); setActiveCatId(category[0]?.id); setActiveCatSubId(categorySub[0]?.id); }}
            >
              {allLanguages?.map(option => (
                <MenuItem key={option.id} value={option.id}>
                  {option.name}
                </MenuItem>
              ))}
            </TextField>
            <TextField
              select
              label="Ana Kateg."
              variant="standard"
              value={activeCatId}
              onChange={(e) => { setActiveCatId(e.target.value); setActiveCatIsSub(false); setActiveCatSubId(categorySub[0]?.id); }}
              disabled={!(category?.length > 0)}
              InputLabelProps={{ shrink: true }}
            >
              {category?.map(option => (
                <MenuItem key={option.id} value={option.id}>
                  {option.name}
                </MenuItem>
              ))}
            </TextField>
            <FormControlLabel
              control={(
                <Switch
                  checked={activeCatIsSub}
                  onChange={() => toggleSwtich()}
                  name="isSub"
                  color="primary"
                  // disabled={!activeCatId}
                  disabled={!(categorySub?.length > 0)}
                />
              )}
              label={!activeCatIsSub ? 'Ana Kateg.' : 'Alt Kateg.'}
            />
            <TextField
              select
              label="Alt Kateg."
              variant="standard"
              value={activeCatSubId}
              onChange={(e) => { setActiveCatSubId(e.target.value); dispatch(thunks.getWord(e.target.value)); }}
              disabled={!(categorySub?.length > 0) || !activeCatIsSub}
              InputLabelProps={{ shrink: true }}
            >
              {categorySub?.map(option => (
                <MenuItem key={option.id} value={option.id}>
                  {option.name}
                </MenuItem>
              ))}
            </TextField>
            <CustomButton purple onClick={() => { setEdit(false); setOpenWordModal(true); setSelectedWordId(null); }} startIcon={<Plus />}>Kelime Ekle</CustomButton>
            <CustomButton purple onClick={() => { setEdit(false); window.location.replace('#/CreateWords/new'); }} startIcon={<Plus />}>Kelime Ekmeleddin</CustomButton>
          </div>
        </Grid>
        <Grid item lg={12} xs={12}>
          <div className="filterButtons">
            <CustomButton orange={activeFilter === 1} purple onClick={() => { setActiveFilter(1); setFilterType('all'); }}>Tümü</CustomButton>
            <CustomButton orange={activeFilter === 2} purple onClick={() => { setActiveFilter(2); setFilterType('false'); }}>Aktif Olan</CustomButton>
            <CustomButton orange={activeFilter === 3} purple onClick={() => { setActiveFilter(3); setFilterType('true'); }}>Pasif Olan</CustomButton>
          </div>
        </Grid>
        <Grid item lg={12} xs={12}>
          <Table
            data={filteredData || []}
            columns={columns || []}
            id="reportsTable"
            options={{
              selectableRows: 'none',
              header: {
                visible: true,
                columnSetting: false,
              },
              filter: {
                show: true,
                filters: [],
                // onChange: (state, value, text, type) => console.log(state, value, text, type),
                // drawerChange: () => setState(prevstate => ({ filterDrawer: !prevstate.filterDrawer })),
              },
              pagination: {
                active: true,
                perPage: 20,
              },
            }}
            // rowClick={row => console.log(row)}
            // rowClick={() => window.location.replace('/SuggestionDetail')}
          />
        </Grid>
      </Grid>
      {
        openWordModal && (
          <NewWord
            open={openWordModal}
            onClose={() => setOpenWordModal(false)}
            edit={edit}
            selectedWordId={selectedWordId}
            wordData={word?.find(select => select?.id === selectedWordId)}
            categoryData={category}
            categorySubData={categorySub}
            activeLangId={activeLangId}
            activeCatId={activeCatId}
            activeCatSubId={activeCatSubId}
          />
        )
      }
      {
        imageDialogOpen && (
          <Dialog
            open={imageDialogOpen}
            onClose={() => setImageDialogOpen(false)}
            fullWidth
            maxWidth="xl"
          >
            <CustomDialog>
              <img src={imageDialogSource} alt="" />
            </CustomDialog>
          </Dialog>
        )
      }
    </PageWrapper>
  );
}

export default Words;
