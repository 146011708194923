import { ClickAwayListener } from '@material-ui/core';
import React, { useCallback, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  AccountTree,
  AllInbox,
  ConfirmationNumber,
  GTranslate,
  LibraryMusic,
  Book,
  HelpSharp,
  PaymentSharp,
  CardGiftcard,
  BugReport,
} from '@material-ui/icons';
import { setAppConfig } from '../store/actions';
import {
  Home,
  Users,
  Logo,
} from '../assets/icons';
import {
  SidebarItem,
  SidebarItemNotLink,
  SidebarWrapper,
  Simplelink,
} from '../assets/styled';
import SendNotifications from './modals/SendNotifications';

function Siedebar() {
  const [auth] = useState(true);
  const [pageLoaded, setPageLoaded] = useState(false);
  const [active, setActive] = useState(1);
  const [notiModal, setNotiModal] = useState(false);

  const sidebarStatu = useSelector(state => state.appConfig?.sidebar);
  const dispatch = useDispatch();
  const setSidebarStatu = useCallback(() => dispatch(setAppConfig('sidebar', true)), [dispatch]);

  useEffect(() => {
    if (window.innerWidth < 768 && !pageLoaded) {
      setSidebarStatu();
      setPageLoaded(true);
    }
  }, [pageLoaded, sidebarStatu, setSidebarStatu]);


  const sideBarList = [
    { icon: <Home />, text: 'Ana Sayfa', hash: '/' },
    (auth && { icon: <Users />, text: 'Kullanıcılar', hash: '/Users' }),
    (auth && { icon: <PaymentSharp />, text: 'Ödemeler', hash: '/Payments' }),
    (auth && { icon: <CardGiftcard />, text: 'Toplu Satışlar', hash: '/BulkSales' }),
    (auth && { icon: <GTranslate />, text: 'Diller', hash: '/Languages' }),
    (auth && { icon: <AccountTree />, text: 'Kategori', hash: '/Categories' }),
    (auth && { icon: <LibraryMusic />, text: 'Kelimeler', hash: '/Words' }),
    (auth && { icon: <AllInbox />, text: 'Paketler', hash: '/Packages' }),
    (auth && { icon: <ConfirmationNumber />, text: 'Kuponlar', hash: '/Coupons' }),
    (auth && { icon: <Book />, text: 'Bloglar', hash: '/Blogs' }),
    (auth && { icon: <BugReport />, text: 'Bug Reports', hash: '/Bug-reports' }),
    (auth && { icon: <HelpSharp />, text: 'Yardımcılar', hash: '/Helpers' }),
    // (auth && { icon: <Gear />, text: 'Ayarlar', hash: '/WebPage' }),
    // (auth && { icon: <AllInbox />, text: 'WebPage', hash: '/WebPage' }),
  ].filter(Boolean);
  const location = (active - 1) * 50;


  useEffect(() => {
    if (!pageLoaded) {
      // const index = sideBarList.findIndex(x => x.hash.split('/')[1] === window.location.hash.split('/')[1]);
      const index = sideBarList.findIndex((x, i) => (window.location.hash !== '#/' ? (i !== 0 && window.location.hash.split('/')[1]?.includes(x.hash.split('/')[1])) : true));
      setActive(index === -1 ? -10 : index + 1);
      setPageLoaded(true);
    }
  }, [pageLoaded, sideBarList]);

  return (
    <>
      <ClickAwayListener
        mouseEvent="none"
        touchEvent="onTouchStart"
        onClickAway={setSidebarStatu}
      >
        <SidebarWrapper open={sidebarStatu}>
          <div className="leftFixed">
            <div className="logo">
              <Simplelink to="/">
                <Logo />
              </Simplelink>
            </div>
            <div className="navigator">
              {
                sideBarList.map((item, i) => (
                  <SidebarItem active={active === i + 1} to={item.hash} onClick={() => { setActive(i + 1); if (window.innerWidth < 768) setSidebarStatu(); }}>
                    {item.icon}
                    <span>
                      {item.text}
                    </span>
                  </SidebarItem>
                ))
              }
              <SidebarItemNotLink onClick={() => { setNotiModal(true); if (window.innerWidth < 768) setSidebarStatu(); }}>
                <AllInbox />
                <span>Bildirim Gönder</span>
              </SidebarItemNotLink>
              <div className="chosen" style={{ top: location }} />
            </div>
          </div>
        </SidebarWrapper>
      </ClickAwayListener>
      <SendNotifications open={notiModal} onClose={() => setNotiModal(false)} />
    </>
  );
}

export default Siedebar;
